import { createSlice } from '@reduxjs/toolkit';
import { REQUEST_STATE } from 'redux/types';
import {
    addExchangeWithdrawalCrypto,
    addExchangeWithdrawal,
    getExchangeWithdrawalCrypto,
    getExchangeWithdrawal,
    getExchangeDeposit,
    getCryptoNetworks,
    getCurrencyCryptoNetwork,
    getDepositARSInfo,
    getDepositCryptoInfo,
} from './walletService';

import {
    mapExchangeDeposit,
    mapDepositARS,
    mapDepositCrypto,
    mapExchangeWithDrawals,
    mapGetCryptoNetworks,
    mapGetCurrencyCryptoNetwork,
    mapExchangeWithdrawalsCrypto,
} from './utils';
import type {
    ARSAdress,
    CryptoAdress,
    ExchangeWithdrawal,
    CryptoNetworks,
    CurrencyCryptoNetwork,
    ExchangeDeposit,
} from './types';

export interface QuotationState {
    requestStates: {
        getExchangeWithdrawal: REQUEST_STATE;
        addExchangeWithdrawal: REQUEST_STATE;
        getExchangeDeposit: REQUEST_STATE;
        getExchangeWithdrawalCrypto: REQUEST_STATE;
        depositARS: REQUEST_STATE;
        depositCrypto: REQUEST_STATE;
        getCryptoNetworks: REQUEST_STATE;
        getCurrencyCryptoNetwork: REQUEST_STATE;
        addExchangeWithdrawalCrypto: REQUEST_STATE;
    };
    exchangeDeposit: ExchangeDeposit[] | null;
    exchangeWithdrawals: ExchangeWithdrawal[] | null;
    exchangeWithdrawalsCrypto: ExchangeWithdrawal[] | null;
    depositARS: ARSAdress | null;
    depositCrypto: CryptoAdress | null;
    cryptoNetworks: CryptoNetworks[] | null;
    currencyCryptoNetwork: CurrencyCryptoNetwork[] | null;
}

const initialState: QuotationState = {
    requestStates: {
        getExchangeWithdrawal: REQUEST_STATE.NONE,
        addExchangeWithdrawal: REQUEST_STATE.NONE,
        getExchangeDeposit: REQUEST_STATE.NONE,
        getExchangeWithdrawalCrypto: REQUEST_STATE.NONE,
        depositARS: REQUEST_STATE.NONE,
        depositCrypto: REQUEST_STATE.NONE,
        getCryptoNetworks: REQUEST_STATE.NONE,
        getCurrencyCryptoNetwork: REQUEST_STATE.NONE,
        addExchangeWithdrawalCrypto: REQUEST_STATE.NONE,
    },
    exchangeWithdrawals: null,
    exchangeDeposit: null,
    exchangeWithdrawalsCrypto: null,
    depositARS: null,
    depositCrypto: null,
    cryptoNetworks: null,
    currencyCryptoNetwork: null,
};

export const walletSlice = createSlice({
    name: 'quotationSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        /* getExchangeWithdrawal */
        builder.addCase(getExchangeWithdrawal.pending, (state) => {
            state.requestStates.getExchangeWithdrawal = REQUEST_STATE.LOADING;
        });
        builder.addCase(
            getExchangeWithdrawal.fulfilled,
            (state, { payload }) => {
                state.requestStates.getExchangeWithdrawal = REQUEST_STATE.OK;
                state.exchangeWithdrawals = mapExchangeWithDrawals(payload);
            },
        );
        builder.addCase(getExchangeWithdrawal.rejected, (state) => {
            state.requestStates.getExchangeWithdrawal = REQUEST_STATE.ERROR;
        });
        /* addExchangeWithdrawal */
        builder.addCase(addExchangeWithdrawal.pending, (state) => {
            state.requestStates.addExchangeWithdrawal = REQUEST_STATE.LOADING;
        });
        builder.addCase(
            addExchangeWithdrawal.fulfilled,
            (state, { payload }) => {
                state.requestStates.addExchangeWithdrawal = REQUEST_STATE.OK;
                if (state.exchangeWithdrawals) {
                    state.exchangeWithdrawals = [
                        ...state.exchangeWithdrawals,
                        ...mapExchangeWithDrawals([payload]),
                    ];
                } else {
                    state.exchangeWithdrawals = [
                        ...mapExchangeWithDrawals([payload]),
                    ];
                }
            },
        );
        builder.addCase(addExchangeWithdrawal.rejected, (state) => {
            state.requestStates.addExchangeWithdrawal = REQUEST_STATE.ERROR;
        });
        /* addExchangeWithdrawalCrypto */
        builder.addCase(addExchangeWithdrawalCrypto.pending, (state) => {
            state.requestStates.addExchangeWithdrawalCrypto =
                REQUEST_STATE.LOADING;
        });
        builder.addCase(
            addExchangeWithdrawalCrypto.fulfilled,
            (state, { payload }) => {
                state.requestStates.addExchangeWithdrawalCrypto =
                    REQUEST_STATE.OK;
            },
        );
        builder.addCase(addExchangeWithdrawalCrypto.rejected, (state) => {
            state.requestStates.addExchangeWithdrawalCrypto =
                REQUEST_STATE.ERROR;
        });
        /* deposit MXN */
        builder.addCase(getDepositARSInfo.pending, (state) => {
            state.requestStates.depositARS = REQUEST_STATE.LOADING;
        });
        builder.addCase(getDepositARSInfo.fulfilled, (state, { payload }) => {
            state.requestStates.depositARS = REQUEST_STATE.OK;
            state.depositARS = mapDepositARS(payload);
        });
        builder.addCase(getDepositARSInfo.rejected, (state) => {
            state.requestStates.depositARS = REQUEST_STATE.ERROR;
        });
        /* deposit Crypto */
        builder.addCase(getDepositCryptoInfo.pending, (state) => {
            state.requestStates.depositCrypto = REQUEST_STATE.LOADING;
        });
        builder.addCase(
            getDepositCryptoInfo.fulfilled,
            (state, { payload }) => {
                state.requestStates.depositCrypto = REQUEST_STATE.OK;
                state.depositCrypto = mapDepositCrypto(payload);
            },
        );
        builder.addCase(getDepositCryptoInfo.rejected, (state) => {
            state.requestStates.depositCrypto = REQUEST_STATE.ERROR;
        });

        /* getExchangeWithdrawalCrypto */
        builder.addCase(getExchangeWithdrawalCrypto.pending, (state) => {
            state.requestStates.getExchangeWithdrawalCrypto =
                REQUEST_STATE.LOADING;
        });
        builder.addCase(
            getExchangeWithdrawalCrypto.fulfilled,
            (state, { payload }) => {
                state.requestStates.getExchangeWithdrawalCrypto =
                    REQUEST_STATE.OK;
                state.exchangeWithdrawalsCrypto =
                    mapExchangeWithDrawals(payload);
            },
        );
        builder.addCase(getExchangeWithdrawalCrypto.rejected, (state) => {
            state.requestStates.getExchangeWithdrawalCrypto =
                REQUEST_STATE.ERROR;
        });

        /* getExchangeDeposit */
        builder.addCase(getExchangeDeposit.pending, (state) => {
            state.requestStates.getExchangeDeposit = REQUEST_STATE.LOADING;
        });
        builder.addCase(getExchangeDeposit.fulfilled, (state, { payload }) => {
            state.requestStates.getExchangeDeposit = REQUEST_STATE.OK;
            state.exchangeDeposit = mapExchangeDeposit(payload);
        });
        builder.addCase(getExchangeDeposit.rejected, (state) => {
            state.requestStates.getExchangeDeposit = REQUEST_STATE.ERROR;
        });
        // /* getCryptoNetworks */
        builder.addCase(getCryptoNetworks.pending, (state) => {
            state.requestStates.getCryptoNetworks = REQUEST_STATE.LOADING;
        });
        builder.addCase(getCryptoNetworks.fulfilled, (state, { payload }) => {
            state.requestStates.getCryptoNetworks = REQUEST_STATE.OK;
            state.cryptoNetworks = mapGetCryptoNetworks(payload);
        });
        builder.addCase(getCryptoNetworks.rejected, (state) => {
            state.requestStates.getCryptoNetworks = REQUEST_STATE.ERROR;
        });

        // /* getCurrencyCryptoNetwork */
        builder.addCase(getCurrencyCryptoNetwork.pending, (state) => {
            state.requestStates.getCurrencyCryptoNetwork =
                REQUEST_STATE.LOADING;
        });
        builder.addCase(
            getCurrencyCryptoNetwork.fulfilled,
            (state, { payload }) => {
                state.requestStates.getCurrencyCryptoNetwork = REQUEST_STATE.OK;
                state.currencyCryptoNetwork =
                    mapGetCurrencyCryptoNetwork(payload);
            },
        );
        builder.addCase(getCurrencyCryptoNetwork.rejected, (state) => {
            state.requestStates.getCurrencyCryptoNetwork = REQUEST_STATE.ERROR;
        });
    },
});

export const walletActions = walletSlice.actions;
export const walletReducer = walletSlice.reducer;
