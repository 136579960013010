import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppDispatch } from 'redux/store';
import { PROFILE_SUB_ROUTES, PROFILE_SUB_ROUTES_KEY } from './const';
import { PaymentMethod } from './PaymentMethod';
import { Preferences } from './Preferences';
import { personalData } from './profileService';
import { Settings } from './Settings/Settings';

export const profileSubRoutes = [
    {
        id: PROFILE_SUB_ROUTES_KEY.SETTINGS,
        path: PROFILE_SUB_ROUTES.SETTINGS,
        element: <Settings />,
    },
    {
        id: PROFILE_SUB_ROUTES_KEY.PREFERENCES,
        path: PROFILE_SUB_ROUTES.PREFERENCES,
        element: <Preferences />,
    },
    {
        id: PROFILE_SUB_ROUTES_KEY.PAYMENT_METHOD,
        path: PROFILE_SUB_ROUTES.PAYMENT_METHOD,
        element: <PaymentMethod />,
    },
];

export const Profile = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(personalData());
    }, []);

    return (
        <Routes>
            {profileSubRoutes.map((route) => (
                <Route key={`profile-sub-route--${route.path}`} {...route} />
            ))}
            <Route
                path='/'
                element={<Navigate to={PROFILE_SUB_ROUTES.SETTINGS} />}
            />
        </Routes>
    );
};
