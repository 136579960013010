import type React from 'react';
import { COIN_TYPES } from 'utils/const';
import type { CoinType } from 'utils/types';
/* Import images */
import { ReactComponent as MxnCoin } from 'assets/images/moneda_pesos.svg';
import { ReactComponent as UsdCoin } from 'assets/images/moneda_usdcoin.svg';
import { ReactComponent as TetherCoin } from 'assets/images/moneda_tether.svg';
import { ReactComponent as SmoothLoveCoin } from 'assets/images/moneda_smoothlovepotion.svg';
import { ReactComponent as LiteCoin } from 'assets/images/moneda_litecoin.svg';
import { ReactComponent as EthCoin } from 'assets/images/moneda_etherum.svg';
import { ReactComponent as BtcCoin } from 'assets/images/moneda_bitcoin.svg';
import { ReactComponent as BatCoin } from 'assets/images/moneda_bat.svg';

export const CoinComponentObject: {
    [key: CoinType]: CoinComponentValue;
} = {
    [COIN_TYPES.MXN]: { component: MxnCoin, text: 'Pesos', id: COIN_TYPES.MXN },
    [COIN_TYPES.BTC]: {
        component: BtcCoin,
        text: 'Bitcoin',
        id: COIN_TYPES.BTC,
    },
    [COIN_TYPES.BAT]: { component: BatCoin, text: 'BAT', id: COIN_TYPES.BAT },
    [COIN_TYPES.USDC]: {
        component: UsdCoin,
        text: 'USD Coin',
        id: COIN_TYPES.USDC,
    },
    [COIN_TYPES.SLP]: {
        component: SmoothLoveCoin,
        text: 'Smooth Love Potion',
        id: COIN_TYPES.SLP,
    },
    [COIN_TYPES.USDT]: {
        component: TetherCoin,
        text: 'USDT',
        id: COIN_TYPES.USDT,
    },
    [COIN_TYPES.ETH]: {
        component: EthCoin,
        text: 'Ethereum',
        id: COIN_TYPES.ETH,
    },
    [COIN_TYPES.LTC]: {
        component: LiteCoin,
        text: 'Litecoin',
        id: COIN_TYPES.LTC,
    },
};

export const CoinComponentObjectArray = Object.values(CoinComponentObject);

export interface CoinComponentValue {
    id: CoinType;
    component: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
    // src: string;
    text: string;
}
