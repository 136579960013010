import axios from 'axios';
import { ALL_URL } from 'redux/url';
import { axiosInterceptorHelper } from './interceptor';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const FIRST_TIME_2FA = 'FIRST_TIME_2FA';
export const RETRIED_REFRESH = 'RETRIED_REFRESH';

/**
 * refreshTokenRequest
 * ==============
 * @description refresh access token using refresh token and set new tokens in local storage
 * @returns access and refresh token
 */

export const refreshTokenRequest = async () => {
    try {
        const authenticated = localStorage.getItem(AUTHENTICATED);
        const refresh = localStorage.getItem(REFRESH_TOKEN);
        if (authenticated !== 'true') throw new Error('');
        const { data } = await axios.post(
            process.env.REACT_APP_PUBLIC_URL + '/' + ALL_URL.REFRESH,
            {
                refresh,
            },
        );
        localStorage.setItem(ACCESS_TOKEN, data.access);
        localStorage.setItem(REFRESH_TOKEN, data.refresh);
        localStorage.setItem(AUTHENTICATED, 'true');
        localStorage.removeItem(RETRIED_REFRESH);
        return data;
    } catch (error: unknown) {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        localStorage.removeItem(AUTHENTICATED);
    }
};

// wait for 10 minutes before refreshing token again
const TEN_MINUTES = 600000;
const getRefreshInterval = () => {
    return setInterval(async () => {
        await refreshTokenRequest();
    }, TEN_MINUTES);
};
let interval = getRefreshInterval();

export const testInterval = () => {
    clearInterval(interval);
    interval = getRefreshInterval();
};

/*
    Axios instance initialization and config
*/

export const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = process.env.REACT_APP_PUBLIC_URL;
axiosInstance.defaults.headers.common = {};

axiosInterceptorHelper(axiosInstance);
