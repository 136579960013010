import { NavLink, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../redux/store';
import type { Section } from '../Sidebar';
import logo from 'axolotl/assets/images/Iso.png';
import './MarketSidebar.scss';
import classNames from 'classnames';
import { useMobile } from '../useMobile';

interface MarketSidebarProps {
    sections: Section[];
}

export const MarketSidebar = (props: MarketSidebarProps) => {
    const sidebarOpen = useAppSelector((state) => state.layout.sidebarOpen);
    const { isMobile } = useMobile();
    const location = useLocation();

    const mainClassName = classNames('axolotl-market-sidebar', {
        closed: !sidebarOpen || isMobile,
    });
    return (
        <header className={mainClassName}>
            <img src={logo} alt='' />
            <div className='links'>
                {props.sections.map((section) => (
                    <NavLink
                        key={`section-nav-link-${section.href}`}
                        className='section-nav-link'
                        to={section.href}
                    >
                        <div className='active-notch' />
                        {location.pathname.includes(section.href)
                            ? section.activeIcon
                            : section.icon}
                    </NavLink>
                ))}
            </div>
        </header>
    );
};
