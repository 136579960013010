import { getTokensFromUrl } from '@utils';
import passwordImage from 'axolotl/assets/images/ilus-log in.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { registerActivate } from '../sessionService';
import { REQUEST_STATE } from 'redux/types';
import { toast } from 'react-toastify';
import { ROUTE_PATH } from 'layout/routes/const';
import { Row } from 'reactstrap';
import { useState } from 'react';
import { FIRST_TIME_2FA } from 'utils/axios';
import { Button } from 'axolotl/common';
import { SessionCard } from 'axolotl/screens/session/SessionCard';

export const RegisterActivate = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { requestStates } = useAppSelector((state) => state.session);
    const { pathname } = useLocation();
    const [isError, setIsError] = useState(false);

    const { uid, token } = getTokensFromUrl(
        ROUTE_PATH.REGISTER_ACTIVATION,
        pathname,
    );

    const handleSubmit = () => {
        dispatch(
            registerActivate({
                uid,
                token,
            }),
        )
            .unwrap()
            .then(() => {
                toast.success(
                    'Tu cuenta está validada, ya puede ingresar a su cuenta con su mail y contraseña',
                );
                navigate('/login');
                localStorage.setItem(FIRST_TIME_2FA, 'true');
            })
            .catch(() => {
                setIsError(true);
                toast.error(
                    'Verifique que la cuenta ya se encuentre activada. En caso contrario contacte el administrador.',
                );
            });
    };

    return (
        <SessionCard
            title='Activación de tu cuenta'
            subtitle='Clickea en el botón para confirmar la activación de tu cuenta.'
            image={passwordImage}
        >
            <Row className='justify-content-center'>
                <Button
                    disabled={isError}
                    type='submit'
                    loading={
                        requestStates.registerActivate === REQUEST_STATE.LOADING
                    }
                    style={{ width: 300 }}
                    onClick={handleSubmit}
                >
                    Activar
                </Button>
            </Row>
        </SessionCard>
    );
};
