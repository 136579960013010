import { Button } from 'axolotl/common';
import { ArrowLeft } from 'react-feather';

interface BackButtonProps {
    onClick: () => void;
}
export const BackButton = (props: BackButtonProps) => (
    <Button onClick={props.onClick} color='secondary' outline>
        <ArrowLeft size={18} className='mr-1' />
        Volver
    </Button>
);
