import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useState, useEffect } from 'react';

interface CircularProgressBarProps {
    seconds: number;
    onTimeOut: () => void;
}

export const CircularProgressBar = (props: CircularProgressBarProps) => {
    const [timeLeft, setTimeLeft] = useState(props.seconds);

    useEffect(() => {
        if (timeLeft === 0) {
            props.onTimeOut();
        }

        // exit when reach 0
        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);

    return (
        <div style={{ width: 50, height: 50 }}>
            <CircularProgressbar
                maxValue={props.seconds}
                value={timeLeft}
                text={`${timeLeft} seg`}
                styles={buildStyles({
                    textColor: '#f37621',
                    strokeLinecap: 'butt',
                    pathColor: '#F37621',
                    trailColor: '#eee',
                })}
            />
        </div>
    );
};
