import { Row } from 'reactstrap';
import { ConvertCryptos } from './ConvertCryptos';
import { TotalBalance } from './TotalBalance';

export const Conversor = () => {
    return (
        <div className='m-3'>
            <Row className='mb-4'>
                <TotalBalance />
            </Row>
            <Row>
                <ConvertCryptos />
            </Row>
        </div>
    );
};
