import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown,
} from 'reactstrap';
import { P } from '@common';
import { useContext } from 'react';
import { ThemeContext } from '../../../providers/ThemeProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import type { SelectOption } from '../FormInput';
import ReactCountryFlag from 'react-country-flag';
import './SelectInput.scss';

interface TextDropdownProps {
    value: string;
    onChange: (option: SelectOption) => void;
    options: SelectOption[];
    disabled?: boolean;
}

export const SelectInput = (props: TextDropdownProps) => {
    const theme = useContext(ThemeContext);
    const dropDownItemColor =
        theme.mix_background_layout === 'light-only' ? 'black' : 'white';
    const getClassName = () => {
        let result = 'text-dropdown';
        if (theme.mix_background_layout === 'dark-only') result += ' dark';
        return result;
    };

    const getCountryFlagProps = (props: TextDropdownProps) => {
        return {
            countryCode:
                props.options.find(
                    (option: SelectOption) => option.id === props.value,
                )?.flag || '',
            svg: props.options.find(
                (option: SelectOption) => option.id === props.value,
            )?.flag
                ? true
                : false,
        };
    };

    return (
        <UncontrolledDropdown
            className={getClassName() + ' ' + 'axolotl-country-select-input'}
            disabled={props.disabled}
        >
            <DropdownToggle
                tag='div'
                className={`form-control ${
                    props.disabled ? 'is-disabled' : ''
                }`}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Row
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <ReactCountryFlag
                        {...getCountryFlagProps(props)}
                        style={{
                            fontSize: '16px',
                            margin: '0 0.5em 0 0.8em',
                        }}
                    />
                    <P
                        style={{
                            fontWeight: '400',
                        }}
                    >
                        {
                            props.options.find(
                                (option) => option.id === props.value,
                            )?.value
                        }
                    </P>
                </Row>

                <FontAwesomeIcon icon={faAngleDown} />
            </DropdownToggle>
            <DropdownMenu
                style={{
                    width: '100%',
                    height: '200px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
                {props.options.map((option) => (
                    <DropdownItem
                        key={`balance-change-dropdown-item-${option.id}`}
                        onClick={() => {
                            props.onChange(option);
                        }}
                    >
                        <Row
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                            }}
                        >
                            <ReactCountryFlag
                                svg
                                countryCode={option.flag || ''}
                                style={{
                                    fontSize: '16px',
                                    margin: '0 0.5em 0 0',
                                }}
                            />
                            <P
                                style={{
                                    color: dropDownItemColor,
                                    fontSize: '14px',
                                    lineHeight: '1.7',
                                    letterSpacing: 'inherit',
                                    fontWeight: '400',
                                    textAlign: 'left',
                                }}
                            >
                                {option.value}
                            </P>
                        </Row>
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};
