import type {
    ExchangeAdviceItem,
    OperatorExchangeOrderItem,
    UserResponse,
    OperatorExchangeWithdrawalItem,
    OperatorExchangeDepositItem,
    OperatorExchangeCryptoWithdrawalItem,
} from './operatorService';
import type {
    ExchangeAdvice,
    OperatorExchangeCryptoWithdrawal,
    OperatorExchangeOrder,
    OperatorExchangeWithdrawal,
    OperatorUser,
    OperatorExchangeDeposit,
} from './types';

export const mapOperatorExchangeOrder = (
    exchangeOrderResponse: OperatorExchangeOrderItem[],
): OperatorExchangeOrder[] =>
    exchangeOrderResponse.map((eo) => ({
        id: eo.id,
        userId: eo.user_id,
        name: eo.name,
        lastname: eo.lastname,
        email: eo.email,
        exchange: eo.exchange,
        balanceMXN: eo.balance_MXN,
        balanceUSDT: eo.balance_USDT,
        tokenpairId: eo.tokenpair_id,
        orderType: eo.order_type,
        orderSide: eo.order_side,
        symbol: eo.symbol,
        amount: eo.amount,
        amountIn: eo.amount_in,
        netPrice: eo.net_price,
        priceTarget: eo.price_target,
        feePercentage: eo.fee_perc,
        fee: eo.fee,
        fiatSpreadPercentage: eo.fiat_spread_perc,
        fiatSpread: eo.fiat_spread,
        createdAt: eo.created_at,
        updatedAt: eo.updated_at,
        active: eo.active,
        executed: eo.executed,
        changeStateBy: eo.change_state_by,
        changeStateAt: eo.change_state_at,
        convertedToQuote: eo.converted_to_quote,
    }));

export const mapOperatorUser = (
    operatorUsers: UserResponse[],
): OperatorUser[] =>
    operatorUsers.map((ou) => ({
        id: ou.id,
        name: ou.name,
        lastname: ou.lastname,
        email: ou.email,
        balanceMXN: ou.balance_MXN,
        balanceUSDT: ou.balance_USDT,
        balanceBTC: ou.balance_BTC,
        balanceETH: ou.balance_ETH,
        cuit: ou.cuit,
        spread: ou.spread,
        phoneCountryCode: ou.phone_country_cod,
        phoneNumber: ou.phone_number,
    }));

export const mapExchangeAdvices = (
    exchangeAdvices: ExchangeAdviceItem[],
): ExchangeAdvice[] =>
    exchangeAdvices.map((ea) => ({
        id: ea.id,
        asset: ea.asset,
        advice: ea.advice,
        min: ea.min,
        max: ea.max,
        active: ea.active,
        done: ea.done,
        currentBalance: ea.current_balance,
        createdAt: ea.created_at,
        doneAt: ea.done_at,
        changeStateBy: ea.change_state_by,
        changeStateAt: ea.change_state_at,
    }));

export const mapOperatorExchangeWithdrawal = (
    exchangeWithdrawalResponse: OperatorExchangeWithdrawalItem[],
): OperatorExchangeWithdrawal[] =>
    exchangeWithdrawalResponse.map((eo) => ({
        id: eo.id,
        userId: eo.user_id,
        currencyId: eo.currency_id,
        amount: eo.amount,
        createdAt: eo.created_at,
        updatedAt: eo.updated_at,
        changeStateAt: eo.change_state_at,
        changeStateBy: eo.change_state_by,
        active: eo.active,
        executed: eo.executed,
        balanceARS: eo.balance_ARS,
        balanceUSDT: eo.balance_USDT,
        balanceBTC: eo.balance_BTC,
        balanceETH: eo.balance_ETH,
        email: eo.email,
        name: eo.name,
        lastname: eo.lastname,
    }));

export const mapOperatorExchangeCryptoWithdrawal = (
    exchangeWithdrawalCryptoResponse: OperatorExchangeCryptoWithdrawalItem[],
): OperatorExchangeCryptoWithdrawal[] =>
    exchangeWithdrawalCryptoResponse.map((eo) => ({
        id: eo.id,
        address: eo.address,
        userId: eo.user_id,
        name: eo.name,
        lastname: eo.lastname,
        email: eo.email,
        balanceARS: eo.balance_ARS,
        balanceUSDT: eo.balance_USDT,
        balanceBTC: eo.balance_BTC,
        balanceETH: eo.balance_ETH,
        currencyId: eo.currency_id,
        amount: eo.amount,
        createdAt: eo.created_at,
        updatedAt: eo.updated_at,
        changeStateAt: eo.change_state_at,
        changeStateBy: eo.change_state_by,
        cryptoNetworkId: eo.cryptonetwork_id,
        active: eo.active,
        executed: eo.executed,
    }));

export const mapOperatorExchangeDeposit = (
    exchangeDepositResponse: OperatorExchangeDepositItem[],
): OperatorExchangeDeposit[] =>
    exchangeDepositResponse.map((eo) => ({
        id: eo.id,
        currencyId: eo.currency_id,
        amount: eo.amount,
        referenceId: eo.reference_id,
        createdAt: eo.created_at,
        updatedAt: eo.updated_at,
        changeStateBy: eo.change_state_by,
        changeStateAt: eo.change_state_at,
        active: eo.active,
        executed: eo.executed,
        address: eo.address,
        balanceARS: eo.balance_ARS,
        balanceUSDT: eo.balance_USDT,
        balanceBTC: eo.balance_BTC,
        balanceETH: eo.balance_ETH,
        cryptonetworkId: eo.cryptonetwork_id,
        cuit: eo.cuit,
        email: eo.email,
        name: eo.name,
        lastname: eo.lastname,
        userId: eo.user_id,
    }));
