import { Card, H5, P } from 'common';
import { CoinComponentObject } from 'common/helper';
import { Table } from 'common/Table';
import { COIN_TYPES } from 'utils/const';
import type { CoinType } from 'utils/types';
import './WalletCard.scss';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useEffect, useState } from 'react';
import { getBalanceExtended, getCoins } from 'common/commonService';
import { dotAndComma, getWalletTableData, twoDecimals } from 'utils/currency';
import { MODAL_OPEN } from 'common/Modal/const';
import { modalActions } from 'common/Modal/modalSlice';
import { REQUEST_STATE } from 'redux/types';
import { quotationActions } from 'screens/role-user/quotation/quotationSlice';
import { CoinCell } from 'common/Table/common-cells/CoinCell';
import { DepositModal } from './DepositModal';
import { WithdrawalModal } from './WithdrawalModal';
import { Button } from 'axolotl/common';

export interface WalletDataItem {
    coin: CoinType;
    amount: string;
    conversion: string;
}

export const WalletCard = () => {
    const dispatch = useAppDispatch();
    const { coins, balances } = useAppSelector((state) => state.common);
    const [tableCoins, setTableCoins] = useState<WalletDataItem[]>([]);
    const [modalData, setModalData] = useState<CoinType>();
    const [isLoading, setIsLoading] = useState(true);
    const balanceState = useAppSelector(
        (state) => state.common.requestStates.getBalance,
    );

    useEffect(() => {
        dispatch(getBalanceExtended());
        dispatch(getCoins());
    }, []);

    useEffect(() => {
        if (balanceState === REQUEST_STATE.OK) {
            setIsLoading(false);
        }
    }, [balanceState]);

    useEffect(() => {
        setTableCoins(getWalletTableData(coins, balances));
    }, [balances]);

    const handleBuyClick = (row: any) => {
        const foundCoin = coins?.find((c) => c.tokenCode === row.coin);
        if (!coins || !foundCoin) return;
        dispatch(
            quotationActions.setBuySellCoinValues({
                cryptoCoin: foundCoin.id.toString() as COIN_TYPES,
                isSelling: false,
            }),
        );
        dispatch(modalActions.setModalOpen(MODAL_OPEN.BUY_DETAIL));
    };

    const handleSellClick = (row: any) => {
        const foundCoin = coins?.find((c) => c.tokenCode === row.coin);
        if (!coins || !foundCoin) return;
        dispatch(
            quotationActions.setBuySellCoinValues({
                cryptoCoin: foundCoin.id.toString() as COIN_TYPES,
                isSelling: true,
            }),
        );
        dispatch(modalActions.setModalOpen(MODAL_OPEN.BUY_DETAIL));
    };

    return (
        <Card className='wallet-card'>
            <H5 style={{ fontWeight: 'bold' }}>Billetera</H5>
            <Table
                loading={isLoading}
                noDataText='La billetera está vacía'
                data={tableCoins}
                columns={[
                    {
                        id: 'coin',
                        header: 'Moneda',
                        cell: (value: CoinType) => (
                            <CoinCell {...CoinComponentObject[value]} />
                        ),
                    },
                    {
                        id: 'amount',
                        header: 'Balance',
                        cell: (value, row) => {
                            const coinName = row.coin;
                            if (row.coin === COIN_TYPES.MXN) {
                                return (
                                    <P
                                        style={{
                                            fontWeight: 600,
                                            fontSize: '14px',
                                            minWidth: 150,
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {twoDecimals(dotAndComma(value))} MXN
                                    </P>
                                );
                            } else if (row.coin === COIN_TYPES.USDT) {
                                return (
                                    <div style={{ width: '100px' }}>
                                        <P
                                            style={{
                                                fontWeight: 600,
                                                fontSize: '14px',
                                                minWidth: 150,
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {twoDecimals(dotAndComma(value))}{' '}
                                            USDT
                                        </P>
                                        <P
                                            color='info'
                                            style={{
                                                lineHeight: '1.0',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            ≈ {dotAndComma(row.conversion)} MXN
                                        </P>
                                    </div>
                                );
                            } else {
                                return (
                                    <div style={{ width: '100px' }}>
                                        <P
                                            style={{
                                                fontWeight: 600,
                                                fontSize: '14px',
                                                minWidth: 150,
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {dotAndComma(value)} {coinName}
                                        </P>
                                        <P
                                            color='info'
                                            style={{
                                                lineHeight: '1.0',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            ≈ {dotAndComma(row.conversion)} MXN
                                        </P>
                                    </div>
                                );
                            }
                        },
                    },
                    {
                        id: 'buttons',
                        header: '',
                        cell: (value, row) => {
                            const coinName = row.coin;
                            return (
                                <div className='wallet-card-buttons'>
                                    <Button
                                        style={{
                                            visibility:
                                                row.coin !== COIN_TYPES.MXN
                                                    ? 'visible'
                                                    : 'hidden',
                                        }}
                                        onClick={() => handleBuyClick(row)}
                                    >
                                        Ir a comprar
                                    </Button>
                                    <Button
                                        color='secondary'
                                        style={{
                                            visibility:
                                                row.coin !== COIN_TYPES.MXN
                                                    ? 'visible'
                                                    : 'hidden',
                                        }}
                                        onClick={() => handleSellClick(row)}
                                    >
                                        Vender
                                    </Button>
                                    <Button
                                        color='primary'
                                        outline
                                        onClick={() => {
                                            setModalData(coinName);
                                            dispatch(
                                                modalActions.setModalOpen(
                                                    MODAL_OPEN.DEPOSIT_MODAL,
                                                ),
                                            );
                                        }}
                                    >
                                        Depositar
                                    </Button>
                                    <Button
                                        color='secondary'
                                        outline
                                        onClick={() => {
                                            dispatch(
                                                modalActions.setModalOpen(
                                                    MODAL_OPEN.WITHDRAWAL_MODAL,
                                                ),
                                            );
                                            setModalData(coinName);
                                        }}
                                    >
                                        Extraer
                                    </Button>
                                </div>
                            );
                        },
                    },
                ]}
            />
            <DepositModal modalData={modalData ? modalData : ''} />
            <WithdrawalModal modalData={modalData} />
        </Card>
    );
};
