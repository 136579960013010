import type {
    TokenPairPrice,
    Balance,
    Coin,
    TokenPair,
    BalanceExtended,
} from '../common/types';
import type { Big as BigObject } from 'big.js';
import Big from 'big.js';
import * as _ from 'lodash';
import { CoinComponentObject } from '../common/helper';
import type { WalletDataItem } from '../screens/role-user/wallet/WalletCard';
import type { TextDropdownOption } from 'common';
import { COIN_TYPES } from './const';
import { ORDER_SIDE } from '../screens/role-user/quotation/types';

export interface balanceEquivalent {
    amountMXN: BigObject;
    amountUSD: BigObject;
}

// max out at 8 decimals, toString to avoid extra 0s
export const formatPrice = (price = '') => {
    return Big(Big(price).toFixed(8)).toString();
};
export const dotToComma = (numberString: string) =>
    numberString.replaceAll('.', ',');

export const dotAndComma = (numberString: string) => {
    if (!numberString) return '0,00';

    // add comma decimal and a dot to the thousands with
    const [integer, decimal] = numberString.split('.');
    //8 digits after comma
    const decimalFixed = decimal ? decimal.slice(0, 8) : ''; //!! NEW

    const integerWithDot = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // if no decimal part, add two 00
    if (!decimalFixed) return `${integerWithDot}`;
    return `${integerWithDot},${decimalFixed}`;
};

export const twoDecimals = (numberString: string) => {
    // remove all after 2 strings after comma
    const splitted = numberString.split(',');
    if (splitted.length === 1) return numberString;
    const decimals = splitted[1];
    if (decimals.length <= 2) return numberString;
    return [splitted[0], decimals.slice(0, 2)].join(',');
};

export const formatAmountChange = (value: string | undefined) => {
    if (!value) return undefined;

    // clean number
    const cleaned = ('' + value).replace(/\D/g, '');

    //add comma decimal
    const formatted = new Big(cleaned).div(100).toFixed(2).replace('.', ',');

    // add dot each 3 digits before the decimal
    const normValue = formatted.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return normValue;
};

export const deformatAmountChange = (value: string | undefined) => {
    if (!value) return undefined;

    // deformating the amount
    const cleaned = ('' + value).replace(/\D/g, '');
    const normValue = new Big(cleaned).div(100).toFixed(2);
    return normValue;
};

export const getTotalBalanceEquivalent = (
    balances: BalanceExtended[] | null,
): balanceEquivalent => {
    const result = { amountMXN: Big(0), amountUSD: Big(0) };
    if (!balances) return result;
    let resultMXN = Big(0);
    let resultUSD = Big(0);
    balances.forEach((b2) => {
        if (b2.amountMXN) resultMXN = resultMXN.plus(b2.amountMXN);
        if (b2.amountUSD) resultUSD = resultUSD.plus(b2.amountUSD);
    });
    result.amountMXN = resultMXN;
    result.amountUSD = resultUSD;
    return result;
};

export const getBuyAndSellPrices = (
    tokenPairPrice: TokenPairPrice,
    amount: string,
    newNetPrice?: string,
): { buy: BigObject; sell: BigObject } => {
    /*
        Formulas v4 final
        ========
        1) buy = netPrice
        2) sell = netPrice
        3) buyAmount = (amount *  (1 - feePerc - fiatSpreadPerc)) / netPrice
        3) sellAmount = (amount *  (1 + feePerc + fiatSpreadPerc)) / netPrice
     */
    const netPrice = Big(newNetPrice || tokenPairPrice.netPrice);
    const _fee = Big(tokenPairPrice.feePercentage);
    const _fiatSpread = Big(tokenPairPrice.fiatSpreadPercentage);
    const _percBelow = Big(1).sub(_fee).sub(_fiatSpread);
    const amountWithFees = Big(amount).mul(_percBelow);
    const amountBuyPrice = amountWithFees.div(netPrice);
    const amountSellPrice = amountWithFees.mul(netPrice);
    return { buy: amountBuyPrice, sell: amountSellPrice };
};

export const getSpreadedNetPrice = (
    netPrice: string,
    fiatSpreadPerc: string,
) => {
    const price = Big(netPrice);
    const buy = price.mul(Big(1).plus(Big(fiatSpreadPerc)));
    const sell = price.mul(Big(1).minus(Big(fiatSpreadPerc)));
    return { buy, sell };
};

export const getComisionPrice = (
    coinFrom: Coin,
    coinTo: Coin,
    tokenPairPrice: TokenPairPrice[] | null,
): string => {
    if (!tokenPairPrice) return '';
    const foundCoin = tokenPairPrice.find(
        (c1) =>
            c1.baseCode === coinTo.tokenCode &&
            c1.quoteCode === coinFrom.tokenCode,
    );
    if (foundCoin === undefined) return 'no';
    return Big(foundCoin.feePercentage).mul(100).toString();
};

export const getWalletTableData = (
    coins: Coin[] | null,
    balance: BalanceExtended[] | null,
): WalletDataItem[] => {
    /*
    const pesos = coins.find('MXN')
    const sortedCoins = coins.filter(c => cc.name !== 'MXN').sort()
    return pesos.concat(sortedCoins)

    la idea es devolver un array de balances filtrados por la data de coins
    Esto es, no devolves coins filtradas, sino balances filtrados
    Si un balance no se encuentra la coin, lo sacamos (mapeando null y usando compact)
    Se filtra antes de mapear porque claramente es más costoso mapear.
    Los nombres de los arrays son confusos pero bueno es un detalle para la posteridad jaja
     */
    if (!coins || !balance) return [];
    const pesos = balance.find((co) => co.tokenCode === 'MXN');
    const sortedBalances = balance
        .filter((cc) => cc.tokenCode !== 'MXN')
        .sort((a, b) => (Big(a.amount).lt(Big(b.amount)) ? 1 : -1));
    const userBalance = _.concat(pesos, sortedBalances);
    if (!userBalance || !sortedBalances) return [];
    return _.compact(
        userBalance.map((bal) => {
            const foundTPP = coins.find(
                (coin) => bal?.tokenCode === coin.tokenCode,
            );
            if (!foundTPP) return null;
            const object = CoinComponentObject[foundTPP.tokenCode];
            if (!object || !bal?.amount) return null;
            return {
                coin: foundTPP.tokenCode,
                amount: Big(bal.amount).toString(),
                conversion: bal.amountMXN ? Big(bal.amountMXN).toFixed(2) : '',
            };
        }),
    );
};

export const getSingleCoinFromId = (
    coins: Coin[] | null,
    id: string,
): string => {
    if (!coins) return '';
    const foundCoin = coins.find((c1) => c1.id.toString() === id.toString());
    if (!foundCoin) return '';
    const coinObject = CoinComponentObject[foundCoin.tokenCode];
    return coinObject ? coinObject.id : '';
};
export const getSingleCoinIDFromName = (
    coins: Coin[] | null,
    name: string,
): number => {
    if (!coins) return 0;
    if (name === 'MXN') {
        const found = coins.find((c1) => c1.name.toString() === 'PESOS MXN');
        return found ? parseInt(found.id) : 0;
    }
    const found = coins.find((c1) => c1.name.toString() === name);
    return found ? parseInt(found.id) : 0;
};

export const getOptionsToTokenPair = (
    tokenPairs: TokenPair[] | null,
    coins: Coin[] | null,
    toCoinId: string,
): TextDropdownOption[] => {
    if (!tokenPairs || !coins) return [];
    const foundToCoin = coins.find((c) => c.id.toString() === toCoinId);
    if (!foundToCoin) return [];
    return _.compact(
        coins.map((c) => {
            const foundTPP = tokenPairs.find(
                (tp) =>
                    c.tokenCode === tp.baseCode &&
                    tp.quoteCode === foundToCoin.tokenCode,
            );
            if (!foundTPP) return null;
            return {
                text: c.tokenCode,
                id: c.id.toString(),
                component: CoinComponentObject[foundTPP.baseCode].component,
            };
        }),
    );
};

export const getOptionsFromTokenPair = (
    tokenPairs: TokenPair[] | null,
    coins: Coin[] | null,
    fromCoinId: string,
): TextDropdownOption[] => {
    if (!tokenPairs || !coins || !fromCoinId) return [];
    const foundFromCoin = coins.find((c) => c.id.toString() === fromCoinId);
    if (!foundFromCoin) return [];
    return _.compact(
        coins
            .filter((c) => c.isCrypto)
            .map((c) => {
                const foundTPP = tokenPairs.find(
                    (tp) =>
                        c.tokenCode === tp.baseCode &&
                        tp.quoteCode === foundFromCoin.tokenCode,
                );
                if (!foundTPP) return null;
                const obj = CoinComponentObject[c.tokenCode];
                return {
                    text: c.tokenCode,
                    id: c.id.toString(),
                    component: obj.component,
                };
            }),
    );
};
export const getEquivalentFromTokenPrice = (
    coins: Coin[] | null,
    tokenPairPrices: TokenPairPrice[] | null,
    formValues: {
        amountFrom: string;
        coinFrom: string;
        coinTo: string;
    },
): string => {
    if (
        !coins ||
        !tokenPairPrices ||
        Object.values(formValues).some((v) => !v) ||
        !formValues.amountFrom ||
        formValues.amountFrom === '0'
    )
        return '';
    const foundFromCoin = coins.find((c) => c.name === formValues.coinFrom);
    const foundToCoin = coins.find((c) => c.name === formValues.coinTo);
    const foundTPP = tokenPairPrices.find(
        (tpp) =>
            tpp.baseCode === foundToCoin?.tokenCode &&
            tpp.quoteCode === foundFromCoin?.tokenCode,
    );
    if (!foundFromCoin || !foundToCoin || !foundTPP) return '';
    return getBuyAndSellPrices(foundTPP, formValues.amountFrom).buy.toFixed(8);
};

export const getMaxBalanceFromCoin = (
    balances: Balance[] | null,
    coins: Coin[] | null,
    coinId: string,
): BigObject | null => {
    if (!balances || !coins) return null;
    const foundCoin = coins.find((c) => c.id.toString() === coinId);
    if (!foundCoin) return null;
    const foundBalance = balances.find(
        (c) => c.tokenCode === foundCoin.tokenCode,
    );
    if (!foundBalance) return null;
    return Big(foundBalance.amount);
};

export const countDecimals = (value: string): number => {
    if (!value) return 0;
    const n = Big(value);
    if (n.toFixed(0) === n.toString()) return 0;
    return value.split('.')[1].length || 0;
};

export const getSelectOptionFromCoins = (
    coins: Coin[] | null,
): TextDropdownOption[] => {
    if (!coins) return [];
    return coins
        .filter((c1) => c1.isActive && c1.isCrypto)
        .map((c2) => ({
            text: c2.name === 'PESOS MXN' ? 'MXN' : c2.name,
            id: c2.id.toString(),
            component: CoinComponentObject[c2.tokenCode].component,
        }));
};

export const getSelectOptionFromTokenPairPrices = (
    tokenPairPrices: TokenPairPrice[] | null,
    coins: Coin[] | null,
    balance: Balance[] | null,
): TextDropdownOption[] => {
    if (!tokenPairPrices || !coins || !balance) return [];
    return _.compact(
        coins
            .filter((c) => c.isCrypto && c.isActive)
            .map((c) => {
                const foundTPP = tokenPairPrices.find(
                    (tpp) => tpp.quoteCode === c.tokenCode,
                );
                const foundBalance = balance.find(
                    (bal) =>
                        bal.tokenCode === c.tokenCode && Big(bal.amount).gt(0),
                );
                if (!foundTPP || !foundBalance) return null;
                return {
                    text: c.tokenCode,
                    id: c.id.toString(),
                    component:
                        CoinComponentObject[foundTPP.quoteCode].component,
                };
            }),
    );
};

// filter is to exist either as base or quote in tokenPairPrices
const __filterOutCoinsNotTPP = (
    coin: Coin,
    tokenPairPrices: TokenPairPrice[],
    filterNonCrypto?: boolean,
): boolean => {
    if (filterNonCrypto && !coin.isCrypto) return false;
    return tokenPairPrices.some((tpp) => {
        return (
            tpp.baseCode === coin.tokenCode || tpp.quoteCode === coin.tokenCode
        );
    });
};

const __mapCoinsToTextDropdownOption = (coin: Coin): TextDropdownOption => ({
    id: coin.id.toString(),
    text: coin.tokenCode,
    component: CoinComponentObject[coin.tokenCode].component,
});

export const convertCryptoUtils = {
    getFromOptions: (
        coins: Coin[] | null,
        tokenPairPrices: TokenPairPrice[] | null,
        filterNonCrypto?: boolean,
        currentToCoinId?: string,
    ): TextDropdownOption[] => {
        if (!coins || !tokenPairPrices) return [];
        return coins
            .filter(
                (c) =>
                    __filterOutCoinsNotTPP(
                        c,
                        tokenPairPrices,
                        filterNonCrypto,
                    ) && c.id.toString() !== currentToCoinId,
            )
            .map(__mapCoinsToTextDropdownOption);
    },
    getMaxAmount: (
        balances: Balance[] | null,
        selectedCoinId: string,
    ): BigObject => {
        const bigNone = Big(0);
        const foundBalance = balances?.find(
            (b) => b.currencyId.toString() === selectedCoinId,
        );
        if (!balances || !foundBalance) return bigNone;
        return Big(foundBalance.amount);
    },
    getToAmountEquivalent: (
        coins: Coin[] | null,
        tokenPairPrices: TokenPairPrice[] | null,
        formValues: {
            fromCoinId: string;
            fromAmount?: string;
            toCoinId: string;
        },
    ): { value: string; type: ORDER_SIDE } => {
        const noneResult = '0.00000000';
        let typeResult = ORDER_SIDE.BUY;
        const foundFromCoin = coins?.find(
            (c) => c.id.toString() === formValues.fromCoinId,
        );
        const foundToCoin = coins?.find(
            (c) => c.id.toString() === formValues.toCoinId,
        );
        if (!coins || !tokenPairPrices || !foundFromCoin || !foundToCoin)
            return { value: noneResult, type: ORDER_SIDE.BUY };
        let result = '';
        tokenPairPrices.forEach((tpp) => {
            // getBuyAndSellPrices(tpp)
            const fromIsQuote = foundFromCoin.tokenCode === tpp.quoteCode;
            const fromIsBase = foundFromCoin.tokenCode === tpp.baseCode;
            const toIsQuote = foundToCoin.tokenCode === tpp.quoteCode;
            const toIsBase = foundToCoin.tokenCode === tpp.baseCode;
            // buying
            if (fromIsQuote && toIsBase) {
                typeResult = ORDER_SIDE.BUY;
                if (formValues.fromAmount) {
                    result = getBuyAndSellPrices(
                        tpp,
                        formValues.fromAmount,
                    ).buy.toFixed(8);
                } else {
                    result = '0.00000000';
                }
            }
            // selling
            if (fromIsBase && toIsQuote) {
                typeResult = ORDER_SIDE.SELL;
                if (formValues.fromAmount) {
                    result = getBuyAndSellPrices(
                        tpp,
                        formValues.fromAmount,
                    ).sell.toFixed(8);
                } else {
                    result = '0.00000000';
                }
            }
        });
        return { value: result, type: typeResult };
    },
    getToOptions: (
        coins: Coin[] | null,
        tokenPairPrices: TokenPairPrice[] | null,
        fromCoinId: string,
        filterNonCrypto?: boolean,
    ): TextDropdownOption[] => {
        const foundFromCoin = coins?.find(
            (c) => c.id.toString() === fromCoinId,
        );
        if (!coins || !tokenPairPrices || !foundFromCoin) return [];
        return coins
            .filter((c) => c.id.toString() !== fromCoinId)
            .filter((c) =>
                __filterOutCoinsNotTPP(c, tokenPairPrices, filterNonCrypto),
            )
            .map(__mapCoinsToTextDropdownOption);
    },
};

export const getUSDTBaseCodeSymbols = (
    tokenPair: TokenPair[] | null,
): string[] => {
    if (!tokenPair) return [];
    return tokenPair
        .filter((tp) => tp.quoteCode === COIN_TYPES.USDT && tp.isCrypto)
        .map((tpp) => tpp.symbol);
};
