import { SessionCard } from 'axolotl/screens/session/SessionCard';
import { useFormik } from 'formik';
import { Col, Form, Row } from 'reactstrap';
import { getTOTP, loginTOTP } from '../sessionService';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { NavLink, useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from 'layout/routes/const';
import { toast } from 'react-toastify';
import login2FAImage from 'axolotl/assets/images/ilus-codigo-de-seguridad.png';
import {
    ACCESS_TOKEN,
    AUTHENTICATED,
    FIRST_TIME_2FA,
    REFRESH_TOKEN,
} from 'utils/axios';
import { useEffect, useState } from 'react';
import { FormInput, ToastBanner } from 'common';
import QRCode from 'react-qr-code';
import { LoaderWheel } from 'common/Loader/LoaderWheel';
import { Button, Text } from 'axolotl/common';
import { REQUEST_STATE } from 'redux/types';

const validationSchema = Yup.object().shape({
    pinCode: Yup.string()
        .length(6, 'Este campo es requerido')
        .required('Este campo es requerido'),
});
export const Login2FA = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            pinCode: '',
        },
        validationSchema,
        onSubmit: (values) => {
            dispatch(loginTOTP(values))
                .unwrap()
                .then(() => {
                    navigate(ROUTE_PATH.ROOT);
                })
                .then(() => navigate(ROUTE_PATH.ROOT))
                .catch(() => toast.error('Código ingresado incorrecto.'));
        },
    });
    const { requestStates, totpCode } = useAppSelector(
        (state) => state.session,
    );
    const loginTOTPState = requestStates.loginTOTP;
    const [forgotQR, setForgotQR] = useState(false);

    const firstTime2FA = localStorage.getItem(FIRST_TIME_2FA) === 'true';
    const showQR = firstTime2FA || forgotQR;

    useEffect(() => {
        if (showQR) {
            dispatch(getTOTP())
                .unwrap()
                .catch(() => {
                    localStorage.removeItem(ACCESS_TOKEN);
                    localStorage.removeItem(REFRESH_TOKEN);
                    localStorage.setItem(AUTHENTICATED, 'false');
                    navigate('/login');
                });
        }
    }, [showQR]);
    return (
        <SessionCard title='Ingresar' subtitle='' image={login2FAImage}>
            <Form className='col' onSubmit={formik.handleSubmit}>
                {showQR && (
                    <>
                        <Row>
                            <ToastBanner
                                show
                                message='Escanea el siguiente código QR usando la aplicación Google Authenticator en tu teléfono móvil.'
                            />
                        </Row>
                        <Row className='mb-4 mt-4'>
                            <Col className='d-flex justify-content-center'>
                                {totpCode ? (
                                    <QRCode value={totpCode} size={200} />
                                ) : (
                                    <LoaderWheel
                                        style={{ width: 200, height: 200 }}
                                    />
                                )}
                            </Col>
                        </Row>
                    </>
                )}
                <Row>
                    <FormInput
                        label='Ingresá el código de seguridad (2FA) generado'
                        name='pinCode'
                        type='pin-password'
                        value={formik.values.pinCode}
                        onChange={() => null}
                        onFormikChange={formik.handleChange}
                        error={formik.errors.pinCode}
                    />
                </Row>
                <Row className='buttons-container'>
                    <Button
                        type='submit'
                        loading={loginTOTPState === REQUEST_STATE.LOADING}
                    >
                        Enviar
                    </Button>
                    {!firstTime2FA && (
                        <Button
                            outline
                            loading={loginTOTPState === REQUEST_STATE.LOADING}
                            onClick={() => setForgotQR(true)}
                        >
                            Olvidé mi código
                        </Button>
                    )}
                </Row>
                <Row className='justify-content-center mt-3'>
                    <Text>
                        ¿No tenés una cuenta?
                        <NavLink className='ms-2 pl-1' to='/registro'>
                            Crear
                        </NavLink>
                    </Text>
                </Row>
            </Form>
        </SessionCard>
    );
};
