import {
    faArrowDown,
    faArrowUp,
    faMinus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, P } from 'common';
import { COLOR } from 'common/types';
import './CryptoDataCardItem.scss';
import Big from 'big.js';
import React from 'react';
import { dotAndComma } from 'utils';
import { formatNumber } from 'utils/string';
import { Text } from 'axolotl/common';
import { LoaderWheel } from 'common/Loader/LoaderWheel';

interface CryptoDataCardItemProps {
    component?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
    title?: string;
    value?: string;
    variation?: string;
    loading: boolean;
}

export const CryptoDataCardItem = (props: CryptoDataCardItemProps) => {
    const getVariationColorAndIcon = () => {
        if (props.variation === '0')
            return { icon: faMinus, color: COLOR.dark };
        if (props.variation === undefined)
            return { icon: faMinus, color: COLOR.dark };
        if (Big(props.variation).gt(0))
            return { icon: faArrowUp, color: COLOR.primary };
        else return { icon: faArrowDown, color: COLOR.danger };
    };

    if (props.loading) {
        return (
            <div className='crypto-card-container'>
                <LoaderWheel />
            </div>
        );
    }
    return (
        <div className='crypto-card-container'>
            <div className='icon'>
                {props.component && (
                    <props.component style={{ width: 54, height: 54 }} />
                )}
            </div>
            <div className='texts'>
                <Text className='price'>{`$${props.value}`}</Text>
                <Text className='coin'>{`${props.title}`}</Text>
            </div>
            <div className='floating-percentage'>
                {props.variation && (
                    <P color={getVariationColorAndIcon().color}>
                        <strong>
                            <FontAwesomeIcon
                                icon={getVariationColorAndIcon().icon}
                                className='mr-1'
                            />
                            {`${dotAndComma(formatNumber(Big(props.variation)))}
                                    %`}
                        </strong>
                    </P>
                )}
            </div>
        </div>
    );
};
