import { BuyModal } from 'common/Modal/BuyModal/BuyModal';
import './Confirm.scss';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getBuyAndSellPrices } from 'utils';
import { ORDER_SIDE } from '../../types';
import { formatNumberWithCommas } from 'utils/string';
import image from 'axolotl/assets/images/new/Ilustracion-01.png';
import { Button, Text } from 'axolotl/common';
import { CoinComponentObject } from 'common/helper';
import {
    cancelExchangeLimit,
    confirmExchangeQuote,
    getExchangeOrders,
} from '../../quotationService';
import { modalActions } from 'common/Modal/modalSlice';
import { MODAL_OPEN } from 'common/Modal/const';
import {
    getBalanceExtended,
    getCoins,
    getTokenPairPrices,
} from 'common/commonService';
import { toast } from 'react-toastify';
import { quotationActions } from '../../quotationSlice';
import { RemainingTimeCounter } from './RemainingTimeCounter';
import { useEffect } from 'react';

export const Confirm = () => {
    const dispatch = useAppDispatch();
    const { coins, tokenPairPrices } = useAppSelector((state) => state.common);
    const { addedExchangeQuote } = useAppSelector((state) => state.quotation);
    const { buySellCoinValues } = useAppSelector((state) => state.quotation);
    const { modalOpen } = useAppSelector((state) => state.modal);

    useEffect(() => {
        dispatch(getCoins());
        dispatch(getTokenPairPrices());
    }, []);

    const getBuyingSellingTexts = () => {
        const foundTPP = tokenPairPrices?.find(
            (tp) => tp.symbol === addedExchangeQuote?.symbol,
        );
        if (!addedExchangeQuote || !tokenPairPrices || !foundTPP)
            return '0,00000000 = 0,00000000';
        const { amount, amount_in, orderSide, priceQuote } = addedExchangeQuote;
        let from = '';
        let to = '';
        const amountValue = getBuyAndSellPrices(foundTPP, amount, priceQuote);
        if (orderSide === ORDER_SIDE.BUY) {
            from = // if coinType is MXN or USDT, show 2 decimals, esle show 8 decimals
                foundTPP.quoteCode === 'MXN' || foundTPP.quoteCode === 'USDT'
                    ? `${formatNumberWithCommas(amount, amount_in, false)}`
                    : formatNumberWithCommas(amount, amount_in);
            to =
                foundTPP.baseCode === 'MXN' || foundTPP.baseCode === 'USDT'
                    ? `${formatNumberWithCommas(
                          amountValue.buy.toString(),
                          foundTPP.baseCode,
                          false,
                      )}`
                    : formatNumberWithCommas(
                          amountValue.buy.toFixed(8),
                          foundTPP.baseCode,
                      );
        }
        if (orderSide === ORDER_SIDE.SELL) {
            from = // if coinType is MXN or USDT, show 2 decimals, esle show 8 decimals
                foundTPP.quoteCode === 'MXN' || foundTPP.quoteCode === 'USDT'
                    ? `${formatNumberWithCommas(amount, amount_in, false)}`
                    : formatNumberWithCommas(amount, amount_in);
            to =
                foundTPP.baseCode === 'MXN' || foundTPP.baseCode === 'USDT'
                    ? `${formatNumberWithCommas(
                          amountValue.sell.toString(),
                          foundTPP.quoteCode,
                          false,
                      )}`
                    : formatNumberWithCommas(
                          amountValue.sell.toFixed(8),
                          foundTPP.quoteCode,
                      );
        }
        return `${from} = ${to}`;
    };

    const getTopText = () => {
        let result = '';
        console.log(buySellCoinValues);
        if (buySellCoinValues?.isSelling) result += 'Vender ';
        else result += 'Comprar ';
        if (!coins || !buySellCoinValues) return result;
        const obj = CoinComponentObject[buySellCoinValues.cryptoCoin];
        result += `${obj.text} (${obj.id})`;
        return result;
    };

    return (
        <BuyModal
            className='confirm-modal'
            open={modalOpen === MODAL_OPEN.BUY_CONFIRM}
            onClose={() => dispatch(modalActions.setModalOpen(MODAL_OPEN.NONE))}
            image={image}
        >
            <Text color='primary' className='top-text'>
                {getTopText()}
            </Text>
            <div className='cardboard-values'>{getBuyingSellingTexts()}</div>
            <Text
                style={{
                    marginTop: 20,
                }}
            >
                Axolotl asegura el precio de compra actual por 60 segundos
            </Text>
            <RemainingTimeCounter />
            <Button
                className='m-3'
                onClick={() => {
                    if (!addedExchangeQuote) return;
                    dispatch(
                        confirmExchangeQuote({
                            id: addedExchangeQuote.id,
                        }),
                    )
                        .unwrap()
                        .then(() => {
                            dispatch(
                                modalActions.setModalOpen(
                                    MODAL_OPEN.BUY_RECEIPT,
                                ),
                            );
                            if (addedExchangeQuote.executeAfterConfirm) {
                                dispatch(
                                    cancelExchangeLimit({
                                        exchange:
                                            addedExchangeQuote.executeAfterConfirm,
                                    }),
                                )
                                    .unwrap()
                                    .then(() => {
                                        dispatch(getExchangeOrders());
                                        dispatch(getBalanceExtended());
                                    })
                                    .catch(() => {
                                        toast.error(
                                            'Hubo un error al ejecutar su orden (paso 2 de 2, cancelar orden de límite), vuelve a intentarlo',
                                        );
                                    });
                            } else {
                                dispatch(getExchangeOrders());
                                dispatch(getBalanceExtended());
                            }
                        })
                        .catch((err: any) => {
                            toast.error(err.message);
                            dispatch(
                                modalActions.setModalOpen(MODAL_OPEN.NONE),
                            );
                        });
                }}
            >
                {addedExchangeQuote?.orderSide === 'BUY' && 'Comprar'}
                {addedExchangeQuote?.orderSide === 'SELL' && 'Vender'}
            </Button>
            <Text
                style={{ textDecoration: 'underline' }}
                onClick={() => {
                    dispatch(
                        quotationActions.setBuyCoinValues({
                            from: '',
                            to: '',
                            fromValue: '',
                            toValue: '',
                        }),
                    );
                    dispatch(modalActions.setModalOpen(MODAL_OPEN.NONE));
                }}
            >
                Cancelar
            </Text>
        </BuyModal>
    );
};
