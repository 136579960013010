import { CircularProgressBar } from 'common';
import { MODAL_OPEN } from 'common/Modal/const';
import { modalActions } from 'common/Modal/modalSlice';
import { differenceInSeconds } from 'date-fns';
import { useAppDispatch, useAppSelector } from 'redux/store';

export const RemainingTimeCounter = () => {
    const dispatch = useAppDispatch();
    const { addedExchangeQuote } = useAppSelector((state) => state.quotation);
    const { modalOpen } = useAppSelector((state) => state.modal);
    const getRemainingSeconds = () => {
        if (!addedExchangeQuote) return 0;
        const { expirationAt, createdAt } = addedExchangeQuote;
        const dif = differenceInSeconds(
            new Date(expirationAt),
            new Date(createdAt),
        );

        const requestTime = differenceInSeconds(
            new Date(),
            new Date(createdAt),
        );

        // dif minus time passed
        const remainingSeconds = dif - requestTime;
        return remainingSeconds;
    };

    return (
        <CircularProgressBar
            seconds={
                // if modal is open, start from 60 seconds
                modalOpen === MODAL_OPEN.BUY_CONFIRM
                    ? getRemainingSeconds()
                    : 60
            }
            onTimeOut={() => {
                dispatch(modalActions.setModalOpen(MODAL_OPEN.NONE));
            }}
        />
    );
};
