import { P } from 'common/Paragraph';
import { useAppSelector } from 'redux/store';
import { USER_TYPE } from 'screens/role-user/profile/types';

interface ExchangeOrderStatusItemProps {
    active: boolean;
    executed: boolean;
    changeStateBy?: number;
    isExtraction?: boolean;
}

export enum ORDER_STATUS {
    ACTIVE,
    EXECUTED,
    SUSPENDED,
    SUSPENDED_BY_OPERATOR,
    SUSPENDED_BY_USER,
    EXTRACTION_DONE,
    EXTRACTION_CANCELLED,
}

const orderStatusText = {
    [ORDER_STATUS.ACTIVE]: 'Activa',
    [ORDER_STATUS.EXECUTED]: 'Ejecutada',
    [ORDER_STATUS.SUSPENDED]: 'Suspendida',
    [ORDER_STATUS.SUSPENDED_BY_OPERATOR]: 'Suspendida por operador',
    [ORDER_STATUS.SUSPENDED_BY_USER]: 'Suspendida por usuario',
    [ORDER_STATUS.EXTRACTION_DONE]: 'Realizada',
    [ORDER_STATUS.EXTRACTION_CANCELLED]: 'Cancelada',
};

const orderStatusColor = {
    [ORDER_STATUS.ACTIVE]: 'green',
    [ORDER_STATUS.EXECUTED]: '',
    [ORDER_STATUS.SUSPENDED]: 'red',
    [ORDER_STATUS.SUSPENDED_BY_OPERATOR]: 'gray',
    [ORDER_STATUS.SUSPENDED_BY_USER]: 'gray',
    [ORDER_STATUS.EXTRACTION_DONE]: 'gray',
    [ORDER_STATUS.EXTRACTION_CANCELLED]: 'red',
};

export const getOrderStatus = (
    exchangeOrderStatusItem: ExchangeOrderStatusItemProps,
    userType: USER_TYPE | null,
    userId: number | undefined,
    isExtraction: boolean,
): ORDER_STATUS => {
    const { active, executed, changeStateBy } = exchangeOrderStatusItem;
    const isActive = active && !executed;
    const userIsOperator = userType === USER_TYPE.OPERATOR;
    if (executed) {
        if (isExtraction) return ORDER_STATUS.EXTRACTION_DONE;
        return ORDER_STATUS.EXECUTED;
    } else if (isActive) {
        return ORDER_STATUS.ACTIVE;
    } else {
        if (isExtraction) return ORDER_STATUS.EXTRACTION_CANCELLED;
        if (userIsOperator) {
            const suspendedByUser = userId !== changeStateBy;
            if (suspendedByUser) {
                return ORDER_STATUS.SUSPENDED_BY_USER;
            } else {
                return ORDER_STATUS.SUSPENDED;
            }
        } else {
            const suspendedByOperator = userId !== changeStateBy;
            if (suspendedByOperator) {
                return ORDER_STATUS.SUSPENDED_BY_OPERATOR;
            } else {
                return ORDER_STATUS.SUSPENDED;
            }
        }
    }
};

export const ExchangeOrderStatusText = (
    props: ExchangeOrderStatusItemProps,
) => {
    const userType = useAppSelector((state) => state.profile.userType);
    const userId = useAppSelector((state) => state.profile.userData?.id);

    const orderStatus = getOrderStatus(
        props,
        userType,
        userId,
        !!props.isExtraction,
    );

    return (
        <P style={{ fontWeight: 'bold', color: orderStatusColor[orderStatus] }}>
            {orderStatusText[orderStatus]}
        </P>
    );
};
