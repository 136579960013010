import { H4, H6 } from '@common';
import { Col, Form, Row } from 'reactstrap';
import type { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoadedImage } from 'utils';
import { BackButton } from 'axolotl/common/BackButton';

interface AxolotlSessionCardProps {
    title: string;
    subtitle: string;
    src: string;
    children?: ReactNode;
    onSubmit: () => void;
    onClose?: () => void;
    noImage?: boolean;
    noBackButton?: boolean;
}

export const AxolotlSessionCard = (props: AxolotlSessionCardProps) => {
    const loaded = useLoadedImage(props.src);
    const navigate = useNavigate();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit();
    };

    return (
        <div className='session-container'>
            <Row className='axolotl-session-card'>
                {props.noImage ? null : (
                    <Col sm='6' className='image-container'>
                        <img
                            src={props.src}
                            alt='login-img'
                            style={{
                                display: !!loaded ? 'block' : 'none',
                            }}
                        />
                    </Col>
                )}
                <Col sm='6' className='form-container'>
                    <Form
                        onSubmit={handleSubmit}
                        className='theme-form login-form needs-validation'
                    >
                        <Row className='m-3'>
                            {props.onClose && (
                                <div className='d-flex' onClick={props.onClose}>
                                    <i className='icofont icofont-close-circled' />
                                </div>
                            )}
                            <div>
                                <div className='box-header'>
                                    <H4> {props.title}</H4>
                                    <H6 fontWeight='400'>{props.subtitle}</H6>
                                </div>
                                {props.children}
                            </div>
                        </Row>
                    </Form>
                </Col>
                {props.noBackButton ? null : (
                    <BackButton onClick={() => navigate(-1)} />
                )}
            </Row>
        </div>
    );
};
