import { getBalanceExtended, getCoins } from 'common/commonService';
import { ThemeContext } from 'providers/ThemeProvider';
import type { MouseEventHandler, ReactNode } from 'react';
import { useState, useContext, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { USER_TYPE } from 'screens/role-user/profile/types';
import logo from 'axolotl/assets/images/logo.png';
import { ROUTE_PATH } from 'layout/routes/const';
import { NavLink, useLocation } from 'react-router-dom';
import { Text } from 'axolotl/common';
import './Sidebar.scss';
import { Svg } from 'axolotl/assets/images/svgs';
import { sessionActions } from 'screens/session/sessionSlice';
import { modalActions } from 'common/Modal/modalSlice';
import { MODAL_OPEN } from 'common/Modal/const';
import { MarketSidebar } from './MarketSidebar';
import { useMobile } from './useMobile';
import classNames from 'classnames';
import { layoutActions } from 'layout/layoutSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { OperatorRobotSwitch } from './OperatorRobotSwitch';
import { OPERATOR_ROBOT_SWITCH_STATE } from './OperatorRobotSwitch/OperatorRobotSwitch';
import { getDaemon, setDaemon } from 'screens/role-operator/operatorService';

export interface Section {
    title?: string;
    href: string; // use as link to another page...
    onClick?: () => void; // ...or simple callback icon?: JSX.Element | string;
    icon?: ReactNode | JSX.Element | string | '';
    activeIcon?: ReactNode;
}

export const Sidebar = () => {
    const dispatch = useAppDispatch();
    const userType = useAppSelector((state) => state.profile.userType);
    const { sidebarOpen } = useAppSelector((state) => state.layout);
    const daemonSwitch = useAppSelector((state) => state.operator.daemonSwitch);
    const theme = useContext(ThemeContext);
    const isDark = theme.mix_background_layout === 'dark-only';
    const location = useLocation();

    useEffect(() => {
        dispatch(getBalanceExtended());
        dispatch(getCoins());
    }, []);

    useEffect(() => {
        if (userType === USER_TYPE.OPERATOR) {
            dispatch(getDaemon());
        }
    }, [userType]);

    const userSections: Section[] = [
        {
            title: 'Panel',
            icon: isDark ? <Svg.PanelDark /> : <Svg.PanelBrown />,
            activeIcon: <Svg.PanelOrange />,
            href: ROUTE_PATH.DASHBOARD,
        },
        {
            title: 'Compra/Venta',
            icon: isDark ? <Svg.BuySellDark /> : <Svg.BuySellBrown />,
            activeIcon: <Svg.BuySellOrange />,
            href: ROUTE_PATH.QUOTATION,
        },
        {
            title: 'Trading',
            icon: isDark ? <Svg.QuotationDark /> : <Svg.QuotationBrown />,
            activeIcon: <Svg.QuotationOrange />,
            href: ROUTE_PATH.MARKET,
        },
        {
            title: 'Conversor',
            icon: isDark ? <Svg.ConversorDark /> : <Svg.ConversorBrown />,
            activeIcon: <Svg.ConversorOrange />,
            href: ROUTE_PATH.CONVERSOR,
        },
        {
            title: 'Billetera',
            icon: isDark ? <Svg.GlassDark /> : <Svg.GlassBrown />,
            activeIcon: <Svg.GlassOrange />,
            href: ROUTE_PATH.WALLET,
        },
        {
            title: 'Perfil',
            icon: isDark ? <Svg.ProfileDark /> : <Svg.ProfileBrown />,
            activeIcon: <Svg.ProfileOrange />,
            href: ROUTE_PATH.PROFILE,
        },
        {
            title: 'Historial',
            icon: isDark ? <Svg.HistoryDark /> : <Svg.HistoryBrown />,
            activeIcon: <Svg.HistoryOrange />,
            href: ROUTE_PATH.HISTORY,
        },
    ];

    const operatorSections: Section[] = [
        {
            title: 'Panel Operador',
            icon: isDark ? <Svg.PanelDark /> : <Svg.PanelBrown />,
            activeIcon: <Svg.PanelOrange />,
            href: ROUTE_PATH.DASHBOARD,
        },
        {
            title: 'Perfiles de usuario',
            icon: isDark ? <Svg.ProfileDark /> : <Svg.ProfileBrown />,
            activeIcon: <Svg.ProfileOrange />,
            href: ROUTE_PATH.USERS,
        },
    ];

    const sections =
        userType === USER_TYPE.OPERATOR ? operatorSections : userSections;

    const mainClassName = classNames('axolotl-header', {
        closed: !sidebarOpen,
    });
    const handleLogout = () => {
        dispatch(sessionActions.logout());
        window.location.href = '/';
    };

    const handleHelp = () => {
        dispatch(modalActions.setModalOpen(MODAL_OPEN.HELP_MODAL));
    };

    if (location.pathname === ROUTE_PATH.MARKET) {
        return <MarketSidebar sections={sections} />;
    }
    return (
        <div className={mainClassName}>
            <img src={logo} alt='' />
            {userType === USER_TYPE.OPERATOR && (
                <div className='robot-switch-container'>
                    <OperatorRobotSwitch
                        onChange={(value) =>
                            dispatch(
                                setDaemon({
                                    switch:
                                        value ===
                                        OPERATOR_ROBOT_SWITCH_STATE.ON,
                                }),
                            )
                        }
                        state={
                            daemonSwitch === null
                                ? OPERATOR_ROBOT_SWITCH_STATE.NONE
                                : daemonSwitch
                                ? OPERATOR_ROBOT_SWITCH_STATE.ON
                                : OPERATOR_ROBOT_SWITCH_STATE.OFF
                        }
                    />
                </div>
            )}
            <div className='nav-links-container'>
                {sections.map((section) => (
                    <NavLink
                        key={`section-nav-link-${section.href}`}
                        className='section-nav-link'
                        to={section.href}
                    >
                        <div className='active-notch' />
                        {location.pathname.includes(section.href)
                            ? section.activeIcon
                            : section.icon}
                        <Text>{section.title}</Text>
                    </NavLink>
                ))}
            </div>
            <div className='logout-and-help-buttons'>
                <div
                    className='bars-toggle'
                    onClick={() =>
                        dispatch(layoutActions.setSidebarOpen(!sidebarOpen))
                    }
                >
                    <FontAwesomeIcon icon={faBars} color='white' />
                </div>
                <LogoutButton onClick={handleLogout} />
                <HelpButton onClick={handleHelp} />
            </div>
        </div>
    );
};

const LogoutButton = ({ onClick }: { onClick: MouseEventHandler }) => {
    const theme = useContext(ThemeContext);
    const isDark = theme.mix_background_layout === 'dark-only';
    const [hover, setHover] = useState(false);

    return (
        <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className='logout-button'
            onClick={onClick}
        >
            {hover && <Svg.LogoutOrange />}
            {!hover && (isDark ? <Svg.LogoutDark /> : <Svg.LogoutBrown />)}
        </div>
    );
};

const HelpButton = ({ onClick }: { onClick: MouseEventHandler }) => {
    const theme = useContext(ThemeContext);
    const isDark = theme.mix_background_layout === 'dark-only';
    const [hover, setHover] = useState(false);

    return (
        <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className='help-button'
            onClick={onClick}
        >
            {hover && <Svg.HelpOrange />}
            {!hover && (isDark ? <Svg.HelpDark /> : <Svg.HelpBrown />)}
        </div>
    );
};
