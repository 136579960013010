import { useState } from 'react';
import { HelpContainer } from '../HelpContainer';
import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Col,
    Row,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { P } from 'common';
import './HelpCenter.scss';

interface HelpCenterProps {
    questions: {
        title: string;
        body: string;
    }[];
}

export const HelpCenter = (props: HelpCenterProps) => {
    const [open, setOpen] = useState('');
    const toggle = (id: string) => {
        if (open === id) {
            setOpen('');
        } else {
            setOpen(id);
        }
    };

    return (
        <HelpContainer title='Preguntas frecuentes' className='help-center'>
            {props.questions &&
                props.questions.map((question, index) => (
                    <Accordion open={open}>
                        <AccordionItem onClick={() => toggle(index.toString())}>
                            <AccordionHeader targetId={index.toString()}>
                                <Row>
                                    <Col className='col-10'>
                                        <P>{question.title}</P>
                                    </Col>
                                    <Col className='col icon'>
                                        {open === index.toString() ? (
                                            <FontAwesomeIcon
                                                icon={faMinusCircle}
                                                color='#F37621'
                                                size='xs'
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={faPlusCircle}
                                                color='#F37621'
                                                size='xs'
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </AccordionHeader>
                            <hr />
                            <AccordionBody accordionId={index.toString()}>
                                <P>{question.body}</P>
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                ))}
        </HelpContainer>
    );
};
