import { FormInput } from '@common';
import { Col, Form, Row } from 'reactstrap';
import passwordImage from 'axolotl/assets/images/ilus-contraseña.png';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { recoverPassword } from '../sessionService';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { REQUEST_STATE } from 'redux/types';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../../layout/routes/const';
import { AxolotlSessionCard } from 'axolotl/screens/session';
import { Button } from 'axolotl/common';
import { SessionCard } from 'axolotl/screens/session/SessionCard';

export interface RecoverPasswordValues {
    email: string;
}

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('El email no es válido')
        .required('El email es requerido'),
});

export const RecoverPassword = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const recoverPasswordState = useAppSelector(
        (state) => state.session.requestStates.recoverPassword,
    );
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: (values) => {
            dispatch(recoverPassword(values))
                .unwrap()
                .then(() => {
                    navigate(ROUTE_PATH.LOGIN);
                });
        },
        validationSchema: validationSchema,
    });

    return (
        <SessionCard
            title='Restablecer mi contraseña'
            subtitle='Escribí tu email y te enviaremos un link para crear una nueva contraseña'
            image={passwordImage}
        >
            <Form className='col' onSubmit={formik.handleSubmit}>
                <Row className='mb-3'>
                    <Col>
                        <FormInput
                            label='Email'
                            name='email'
                            type='email'
                            onFormikChange={formik.handleChange}
                            onChange={() => null}
                            value={formik.values.email}
                            error={formik.errors.email}
                            placeholder='example@mail.com'
                        />
                    </Col>
                </Row>
                <Row className='justify-content-left'>
                    <Button
                        type='submit'
                        loading={recoverPasswordState === REQUEST_STATE.LOADING}
                        style={{ marginLeft: '15px', marginTop: '15px' }}
                    >
                        Enviar
                    </Button>
                </Row>
            </Form>
        </SessionCard>
    );
};
