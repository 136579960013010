import './TotalBalance.scss';
import { Text } from 'axolotl/common';
import { dotAndComma, getTotalBalanceEquivalent } from 'utils';
import { useAppSelector } from 'redux/store';
import knob from 'axolotl/assets/images/new/knob.png';

export const TotalBalance = () => {
    const { balances } = useAppSelector((state) => state.common);

    const totalBalanceEquivalent = getTotalBalanceEquivalent(balances);
    return (
        <div className='total-balance'>
            <div className='left-texts'>
                <Text className='title'>Balance total</Text>
                <Text className='description'>Mostrado en MXN</Text>
            </div>
            <div className='right-balance'>
                <img src={knob} alt='' />
                <Text className='value'>
                    {`$${dotAndComma(
                        totalBalanceEquivalent.amountMXN.toFixed(2),
                    )}`}
                </Text>
            </div>
        </div>
    );
};
