import classNames from 'classnames';
import type { CSSProperties, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import type { TextColor } from './types';

interface TextProps {
    title?: boolean;
    children?: ReactNode;
    link?: string;
    className?: string;
    style?: CSSProperties;
    color?: TextColor;
    onClick?: () => void;
}

export const Text = (props: TextProps) => {
    const { className: propsClassName, color, link, title, ...rest } = props;

    const className = classNames(
        props.link ? 'axolotl-link-text' : 'axolotl-text',
        { ['color-' + color]: color, clickable: rest.onClick },
        propsClassName,
    );

    if (title) {
        return (
            <h1 className={className} {...rest}>
                {props.children}
            </h1>
        );
    } else if (link) {
        return (
            <NavLink className={className} to={link} {...rest}>
                {props.children}
            </NavLink>
        );
    } else {
        return (
            <p className={className} style={props.style} {...rest}>
                {props.children}
            </p>
        );
    }
};
