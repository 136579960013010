import { Text } from 'axolotl/common';
import classNames from 'classnames';
import { Card } from 'common';
import type { ReactNode } from 'react';
import './HelpContainer.scss';

interface HelpPageProps {
    title: string;
    children?: ReactNode;
    className?: string;
}

export const HelpContainer = (props: HelpPageProps) => {
    const className = classNames(props.className);
    return (
        <div className='help-container'>
            <Card className={className}>
                <Text className='title'>{props.title}</Text>
                {props.children}
            </Card>
        </div>
    );
};
