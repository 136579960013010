import { Button, Text } from 'axolotl/common';
import Big from 'big.js';
import {
    getBalance,
    getCoins,
    getTokenPairPrices,
    getTokenPairs,
} from 'common/commonService';
import { CoinComponentObject } from 'common/helper';
import type { Coin, TokenPairPrice } from 'common/types';
import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import { Form } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { dotAndComma } from 'utils';
import { COIN_TYPES } from 'utils/const';
import { convertCryptoUtils, twoDecimals } from 'utils/currency';
import { InputAmount } from '../../InputAmount';
import './Details.scss';
import image from 'axolotl/assets/images/new/Ilustracion-01.png';
import { BuyModal } from 'common/Modal/BuyModal/BuyModal';
import { MODAL_OPEN } from 'common/Modal/const';
import { modalActions } from 'common/Modal/modalSlice';
import { formatNumberWithCommas } from 'utils/string';
import { toast } from 'react-toastify';
import { ORDER_SIDE } from '../../types';
import { addExchangeQuote } from '../../quotationService';
import { quotationActions } from '../../quotationSlice';

export const Details = () => {
    const dispatch = useAppDispatch();
    const { coins, balances, tokenPairPrices } = useAppSelector(
        (state) => state.common,
    );
    const { buySellCoinValues } = useAppSelector((state) => state.quotation);
    const { modalOpen } = useAppSelector((state) => state.modal);

    const formik = useFormik({
        initialValues: {
            fromAmount: '',
            toAmountEquivalent: '',
        },
        // Note: wrongfuly managed in the button onClick
        onSubmit: () => {
            console.log('submit');
        },
    });

    useEffect(() => {
        dispatch(getCoins());
        dispatch(getBalance());
        dispatch(getTokenPairPrices());
        dispatch(getTokenPairs());
    }, []);

    const { fromCoin, toCoin, tokenPairPrice } = useMemo(() => {
        const result: {
            fromCoin: Coin | null;
            toCoin: Coin | null;
            tokenPairPrice: TokenPairPrice | null;
        } = {
            fromCoin: null,
            toCoin: null,
            tokenPairPrice: null,
        };
        if (!coins || !buySellCoinValues) return result;
        coins.forEach((c) => {
            if (buySellCoinValues.isSelling) {
                if (buySellCoinValues.cryptoCoin === c.id.toString())
                    result.fromCoin = c;
                if (COIN_TYPES.MXN === c.tokenCode) result.toCoin = c;
            } else {
                if (buySellCoinValues.cryptoCoin === c.id.toString())
                    result.toCoin = c;
                if (COIN_TYPES.MXN === c.tokenCode) result.fromCoin = c;
            }
        });
        if (!result.fromCoin || !result.toCoin || !tokenPairPrices)
            return result;
        const tppFound = tokenPairPrices.find(
            (tpp) =>
                tpp.baseCode === result.toCoin?.tokenCode &&
                tpp.quoteCode === result.fromCoin?.tokenCode,
        );
        if (!tppFound) return result;
        result.tokenPairPrice = tppFound;
        return result;
    }, [coins, buySellCoinValues]);

    const handleFromChange = (value: string) => {
        if (!fromCoin || !toCoin) return;
        const limitedFromValue = _limitAmount(fromCoin.id.toString(), value);
        formik.setFieldValue('fromAmount', limitedFromValue);
        formik.setFieldValue(
            'toAmountEquivalent',
            convertCryptoUtils.getToAmountEquivalent(coins, tokenPairPrices, {
                fromCoinId: fromCoin.id.toString(),
                toCoinId: toCoin.id.toString(),
                fromAmount: limitedFromValue,
            }).value,
        );
    };

    const _limitAmount = (fromCoinId: string, value: string): string => {
        if (!balances) return '';
        const maxBalance = _getMaxBalance(fromCoinId);
        if (!value || maxBalance.eq(Big(0))) return '';
        if (Big(value).gt(maxBalance)) return maxBalance.toString();
        if (Big(value).lt(Big(0))) return '0';
        return value;
    };

    const _getMaxBalance = (fromCoinId: string) =>
        convertCryptoUtils.getMaxAmount(balances, fromCoinId);

    const getTitle = () => {
        let result = '';
        if (buySellCoinValues?.isSelling) result += 'Vender ';
        else result += 'Comprar ';
        const foundCoin = coins?.find(
            (c) => c.id.toString() === buySellCoinValues?.cryptoCoin,
        );
        if (!coins || !buySellCoinValues || !foundCoin) return result;
        const obj = CoinComponentObject[foundCoin.tokenCode];
        result += `${obj.text} (${obj.id})`;
        return result;
    };

    const getSubtitle = () => {
        if (!fromCoin || !buySellCoinValues) return '';

        let result = 'Usar todos mis fondos';
        const maxBalance = dotAndComma(
            _getMaxBalance(fromCoin.id.toString()).toString(),
        );

        // if coinType is MXN or USDT, fixed 2 decimals
        if (
            fromCoin.tokenCode === COIN_TYPES.MXN ||
            fromCoin.tokenCode === COIN_TYPES.USDT
        )
            return (result += ` (${twoDecimals(maxBalance)} ${
                fromCoin.tokenCode
            })`);

        result += ` (${maxBalance.toString()} ${fromCoin.tokenCode})`;
        return result;
    };

    const getEquivalent = () => {
        if (!formik.values.toAmountEquivalent)
            return `≈ 0,00 ` + toCoin?.tokenCode;
        // if coinType is MXN or USDT, fixed 2 decimals
        if (
            toCoin?.tokenCode === COIN_TYPES.MXN ||
            toCoin?.tokenCode === COIN_TYPES.USDT
        )
            return (
                `≈ ${formatNumberWithCommas(
                    formik.values.toAmountEquivalent,
                    toCoin.tokenCode,
                    true,
                )} ` + toCoin?.tokenCode
            );

        return `≈ ${dotAndComma(
            Big(formik.values.toAmountEquivalent).toFixed(8),
        )} ${toCoin?.tokenCode}`;
    };

    return (
        <BuyModal
            className='details-modal'
            open={modalOpen === MODAL_OPEN.BUY_DETAIL}
            onClose={() => dispatch(modalActions.setModalOpen(MODAL_OPEN.NONE))}
            image={image}
        >
            <Text
                title
                color='primary'
                style={{
                    fontSize: 24,
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    marginTop: 50,
                }}
            >
                {getTitle()}
            </Text>
            <Text
                color='primary'
                style={{
                    fontSize: '14px',
                    marginBottom: '2px',
                    textDecoration: 'underline',
                    color: '#2c323f',
                }}
                onClick={() => {
                    if (!fromCoin) return;
                    handleFromChange(
                        _getMaxBalance(fromCoin.id.toString()).toString(),
                    );
                }}
            >
                {getSubtitle()}
            </Text>
            <Form onSubmit={formik.handleSubmit}>
                <InputAmount
                    value={formik.values.fromAmount}
                    onChange={handleFromChange}
                    coinType={fromCoin?.tokenCode || ''}
                    equivalent={getEquivalent()}
                />
            </Form>
            <Text style={{ marginTop: 20, marginBottom: 40 }}>
                {`Ten en cuenta que la compra de ${fromCoin?.tokenCode} tiene ${
                    tokenPairPrice
                        ? formatNumberWithCommas(
                              tokenPairPrice.feePercentage,
                              undefined,
                              undefined,
                              true,
                          ) + '%'
                        : '0.00%'
                } de comisión.`}
            </Text>
            <Button
                onClick={() => {
                    const foundTPP = tokenPairPrices?.find(
                        (tpp) =>
                            (tpp.quoteCode === fromCoin?.tokenCode &&
                                tpp.baseCode === toCoin?.tokenCode) ||
                            (tpp.baseCode === fromCoin?.tokenCode &&
                                tpp.quoteCode === toCoin?.tokenCode),
                    );
                    if (!fromCoin || !toCoin || !foundTPP || !buySellCoinValues)
                        return;
                    dispatch(
                        addExchangeQuote({
                            amount: formik.values.fromAmount,
                            tokenpair_id: foundTPP.tokenpairId,
                            order_side: buySellCoinValues.isSelling
                                ? ORDER_SIDE.SELL
                                : ORDER_SIDE.BUY,
                            amount_in: '',
                            symbol: '',
                            executeAfterConfirm: null,
                        }),
                    )
                        .unwrap()
                        .then(() => {
                            formik.setFieldValue('fromAmount', '');
                            dispatch(
                                modalActions.setModalOpen(
                                    MODAL_OPEN.BUY_CONFIRM,
                                ),
                            );
                            formik.setFieldValue('toAmountEquivalent', '');
                        })
                        .catch((err: any) => {
                            toast.error(err.message);
                        });
                }}
            >
                Continuar
            </Button>
            <Text
                style={{
                    textDecoration: 'underline',
                    marginTop: 10,
                    marginBottom: 20,
                }}
                onClick={() => {
                    dispatch(
                        quotationActions.setBuyCoinValues({
                            from: '',
                            to: '',
                            fromValue: '',
                            toValue: '',
                        }),
                    );
                    formik.setFieldValue('fromAmount', '');
                    dispatch(modalActions.setModalOpen(MODAL_OPEN.NONE));
                    formik.setFieldValue('toAmountEquivalent', '');
                }}
            >
                Cancelar
            </Text>
            <Text color='primary' style={{ marginBottom: 20 }}>
                En el próximo paso vas a poder revisar la cotización actual
                antes de confirmar la compra
            </Text>
        </BuyModal>
    );
};
