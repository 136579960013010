import { Col, Form, Row } from 'reactstrap';
import { HelpContainer } from '../HelpContainer';
import { FormInput } from 'common';
import { useFormik } from 'formik';
import { getSelectOptionFromCountries } from 'common/FormInput/utils';
import { useAppDispatch, useAppSelector } from 'redux/store';
import * as Yup from 'yup';
import './SendConsult.scss';
import { useEffect } from 'react';
import { getCountries } from 'common/commonService';
import { Button } from 'axolotl/common';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email('Formato de mail no válido')
        .required('Este campo es requerido'),
    country: Yup.string().required('Este campo es requerido'),
    subject: Yup.string().required('Este campo es requerido'),
    files: Yup.string(),
    text: Yup.string(),
});

export const SendConsult = () => {
    const dispatch = useAppDispatch();
    const { countries } = useAppSelector((state) => state.common);

    const formik = useFormik({
        initialValues: {
            email: '',
            country: '',
            subject: '',
            files: '',
            text: '',
        },
        validationSchema,
        onSubmit: (values) => {
            console.log(values);
        },
    });

    useEffect(() => {
        dispatch(getCountries());
    }, []);
    return (
        <HelpContainer className='send-consult' title='Enviar una solicitud'>
            {/* <Text>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</Text> */}
            <Form className='col' onSubmit={formik.handleSubmit}>
                <Row>
                    <Col>
                        <FormInput
                            label='Correo electrónico'
                            name='email'
                            type='email'
                            value={formik.values.email}
                            onFormikChange={formik.handleChange}
                            onChange={() => null}
                            placeholder='example@mail.com'
                            error={formik.errors.email}
                        />
                    </Col>
                    <Col>
                        <FormInput
                            label='País'
                            name='country'
                            type='select'
                            options={getSelectOptionFromCountries(countries)}
                            value={formik.values.country}
                            onFormikChange={formik.handleChange}
                            onChange={() => null}
                            placeholder='México'
                            error={formik.errors.country}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormInput
                            label='Asunto de la consulta'
                            name='subject'
                            value={formik.values.subject}
                            onFormikChange={formik.handleChange}
                            onChange={() => null}
                            placeholder='Asunto'
                            error={formik.errors.subject}
                        />
                    </Col>
                    <Col>
                        <FormInput
                            label='Archivos adjuntos'
                            name='files'
                            type='file'
                            value={formik.values.files}
                            onFormikChange={formik.handleChange}
                            onChange={() => null}
                            placeholder='Agregue un archivo o suelte archivos aquí'
                            error={formik.errors.files}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormInput
                            label='¿Cómo podemos ayudarte?'
                            name='text'
                            type='textarea'
                            value={formik.values.text}
                            onFormikChange={formik.handleChange}
                            onChange={() => null}
                            placeholder='Ingrese los detalles de esta solicitud.'
                            error={formik.errors.text}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className='d-flex justify-content-center mt-4'>
                        <Button style={{ width: 180 }} type='submit'>
                            Enviar
                        </Button>
                    </Col>
                </Row>
            </Form>
        </HelpContainer>
    );
};
