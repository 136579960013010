import classNames from 'classnames';
import { LoaderWheel } from 'common/Loader/LoaderWheel';
import type { CSSProperties, MouseEvent, ReactNode } from 'react';
import type { ButtonColor, ButtonSize } from './types';

interface ButtonProps {
    onClick?: (e: MouseEvent<HTMLElement>) => void;
    size?: ButtonSize;
    color?: ButtonColor;
    className?: string;
    children?: ReactNode;
    style?: CSSProperties;
    loading?: boolean;
    disabled?: boolean;
    outline?: boolean;
    type?: 'submit';
}

export const Button = (props: ButtonProps) => {
    const {
        size,
        color,
        className: propsClassName,
        children,
        loading,
        disabled,
        outline,
        onClick,
        ...rest
    } = props;

    const className = classNames(
        'axolotl',
        {
            disabled: loading || disabled,
            outline,
            ['size-' + size]: size,
        },
        propsClassName,
        'color-' + (color || 'primary'),
    );

    const handleClick = (e: MouseEvent<HTMLElement>) => {
        if (!props.type) e.preventDefault();
        if (onClick && !disabled && !loading) onClick(e);
    };

    return (
        <button className={className} onClick={handleClick} {...rest}>
            {loading ? <LoaderWheel white /> : children}
        </button>
    );
};
