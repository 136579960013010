import Slider from 'react-slick';
import { CryptoDataCardItem } from 'screens/role-user/dashboard/CoinCarousel/CryptoDataCardItem/CryptoDataCardItem';
import './CoinCarousel.scss';

export interface CardCoin {
    component?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
    title?: string;
    value?: string;
    variation?: string;
    loading?: boolean;
}

interface SlickProps {
    data: CardCoin[];
    loading: boolean;
}

export const SlickCarousel = (props: SlickProps) => {
    // count the objects in the props array
    const count = props.data.length;

    // if there are 6 or more objects, show 6 objects per slide
    const show = count >= 6 ? 6 : count;

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        rows: 2,
        responsive: [
            {
                breakpoint: 3300,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                },
            },
            {
                breakpoint: 2700,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 2300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 1900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <Slider {...settings} className='slick-carousel'>
            {props.loading
                ? [1, 2, 3, 4, 5, 6].map((i) => (
                      <div key={i}>
                          <CryptoDataCardItem loading={props.loading} />
                      </div>
                  ))
                : props.data.map((item, index) => (
                      <div key={index} className='carousel-cards'>
                          <CryptoDataCardItem {...item} loading={false} />
                      </div>
                  ))}
        </Slider>
    );
};
