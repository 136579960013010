import type { ReactNode } from 'react';
import { Table as ReactstrapTable } from 'reactstrap';
import { P } from '@common';
import './Table.scss';
import { Fragment, useContext } from 'react';
import { ThemeContext } from '../../providers/ThemeProvider';
import { LoaderWheel } from '../Loader/LoaderWheel/LoaderWheel';

interface TableProps {
    data: Record<string, any>[];
    columns: {
        id: string;
        header: ReactNode;
        cell?: (value: any, row: Record<string, any>) => ReactNode;
    }[];
    className?: string;
    center?: boolean;
    loading?: boolean;
    noDataText: string;
}

export const Table = (props: TableProps) => {
    const theme = useContext(ThemeContext);

    const getClassName = () => {
        let result = '';
        if (theme.mix_background_layout === 'dark-only') result += ' dark';
        return result;
    };

    return (
        <ReactstrapTable borderless>
            <thead>
                <tr>
                    {props.columns.map((column, index) => (
                        <Fragment key={`col-${index}`}>
                            {(props.center &&
                                (index === 0 ? (
                                    <th
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {column.header}
                                    </th>
                                ) : (
                                    <th key={index}>{column.header}</th>
                                ))) || <th key={index}>{column.header}</th>}
                        </Fragment>
                    ))}
                </tr>
            </thead>
            <tbody>
                {
                    // if props.loading is true, show LoadWheel
                    props.loading ? (
                        <tr>
                            <td colSpan={props.columns.length}>
                                <LoaderWheel />
                            </td>
                        </tr>
                    ) : // if props.data is empty, show noDataText
                    props.data.length === 0 ? (
                        <tr>
                            <td colSpan={props.columns.length}>
                                <P className='no-data-text'>
                                    {props.noDataText}
                                </P>
                            </td>
                        </tr>
                    ) : (
                        props.data.map((row, index) => {
                            return (
                                <tr key={index} className={getClassName()}>
                                    {props.columns.map((column, index) => {
                                        const value = row[column.id];
                                        const content = props.columns[
                                            index
                                        ].cell?.(value, row);
                                        if (!content) {
                                            return (
                                                <td key={index}>
                                                    <P className='elementsTable'>
                                                        {value}
                                                    </P>
                                                </td>
                                            );
                                        } else if (
                                            typeof content === 'string'
                                        ) {
                                            return (
                                                <td key={index}>
                                                    <P className='elementsTable'>
                                                        {content}
                                                    </P>
                                                </td>
                                            );
                                        } else {
                                            return (
                                                <td key={index}>{content}</td>
                                            );
                                        }
                                    })}
                                </tr>
                            );
                        })
                    )
                }
            </tbody>
        </ReactstrapTable>
    );
};
