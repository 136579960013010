import {
    Col,
    Card as ReacstrapCard,
    CardHeader,
    CardBody,
    CardFooter,
} from 'reactstrap';
import { H5 } from '../Headings/H5Element';
import type { Color } from 'common/types';
import { cardColors } from './const';
import type { CSSProperties, ReactNode } from 'react';

interface CardProps {
    color?: Color;
    bodyColor?: Color;
    footerColor?: Color;
    headerColor?: Color;
    title?: string;
    icon?: JSX.Element;
    footer?: JSX.Element | string;
    settingIcon?: boolean;
    settingPrimary?: boolean;
    subtitle?: string;
    children?: ReactNode;
    style?: CSSProperties;
    width?: string;
    height?: string;
    margin?: string;
    padding?: string;
    borderRadius?: string;
    flexGrow?: number;
    backgroundImage?: string;
    className?: string;
    noPadding?: boolean;
    noOverflow?: boolean;
    gradientBackground?: boolean;
}

export const Card = (props: CardProps) => {
    const getClassName = (colorProp?: Color, hasBorder?: boolean) => {
        let result = '';
        if (props.color) result += cardColors[props.color];
        else if (colorProp) result += cardColors[colorProp || props.color];
        if (props.gradientBackground) result += ' gradient-background';
        return result + (hasBorder ? ' border-bottom' : '');
    };
    const overflow = props.noOverflow === true ? 'visible' : 'auto';

    return (
        <Col style={props.style} className={props.className}>
            <ReacstrapCard
                style={{
                    borderRadius: props.borderRadius || '20px',
                    width: props.width,
                    height: props.height,
                    margin: props.margin,
                    padding: props.padding,
                    flexGrow: props.flexGrow,
                    backgroundImage: props.backgroundImage,
                }}
            >
                {props.title && (
                    <CardHeader
                        style={{ borderRadius: '20px 20px 0 0' }}
                        className={getClassName(props.headerColor, true)}
                    >
                        <H5>
                            {props.icon ? props.icon : ''}
                            {props.title}
                        </H5>
                        {props.subtitle ? <span>{props.subtitle}</span> : ''}
                    </CardHeader>
                )}
                <CardBody
                    style={{
                        borderRadius: !props.footer ? 20 : '',
                        padding: props.noPadding ? 0 : '',
                        overflowX: overflow,
                    }}
                    className={getClassName(props.bodyColor)}
                >
                    {props.children}
                </CardBody>
                {props.footer && (
                    <CardFooter
                        style={{ borderRadius: '0 0 20px 20px' }}
                        className={getClassName(props.footerColor)}
                    >
                        {props.footer}
                    </CardFooter>
                )}
            </ReacstrapCard>
        </Col>
    );
};
