import { Button, Text } from 'axolotl/common';
import { TEXT_COLOR } from 'axolotl/common/Text/types';
import { FormInput } from 'common';
import { MODAL_OPEN } from 'common/Modal/const';
import { modalActions } from 'common/Modal/modalSlice';
import type { Coin } from 'common/types';
import { Form, Row } from 'reactstrap';
import { useAppDispatch } from 'redux/store';
import { quotationActions } from 'screens/role-user/quotation/quotationSlice';
import { dotAndComma } from 'utils';
import type { COIN_TYPES } from 'utils/const';
import type { Big as BigObject } from 'big.js';

interface BuySellButtonsProps {
    form: any;
    foundMXNCoin: Coin;
    row: any;
    handleChange: (
        id: string,
        type: string,
        value: string,
        errorText: string,
    ) => void;
    _limitAmount: (fromCoinId: string, value: string) => string;
    validForm: boolean;
    loading: boolean;
    handleBuyCoinWithMXN: (row: any) => void;
    foundRowToCoin: Coin;
    _getMaxBalance: (fromCoinId: string) => BigObject;
}

export const BuySellButtons = (props: BuySellButtonsProps) => {
    const dispatch = useAppDispatch();
    const {
        form,
        foundMXNCoin,
        row,
        handleChange,
        _limitAmount,
        validForm,
        loading,
        handleBuyCoinWithMXN,
        foundRowToCoin,
        _getMaxBalance,
    } = props;

    console.log(props);
    return (
        <Form className='quotation-buy-sell-buttons'>
            <Row className='input-and-buttons'>
                <FormInput
                    label=''
                    name='input1'
                    type='number'
                    value={form.values[row.coin + '-text']}
                    onChange={(newValue) => {
                        if (!foundMXNCoin) return;
                        handleChange(
                            row.coin + '-text',
                            'input1',
                            _limitAmount(foundMXNCoin?.id.toString(), newValue),
                            '',
                        );
                    }}
                    placeholder='0,00000000'
                    error={form.errors.input1}
                />
                <Button
                    className='buy-button'
                    disabled={
                        !validForm ||
                        !form.values[row.coin + '-text'] ||
                        loading
                    }
                    onClick={() => handleBuyCoinWithMXN(row)}
                >
                    Comprar
                </Button>
                <Button
                    className='sell-button'
                    color='secondary'
                    disabled={!validForm || loading}
                    onClick={() => {
                        dispatch(
                            quotationActions.setBuySellCoinValues({
                                cryptoCoin:
                                    foundRowToCoin.id.toString() as COIN_TYPES,
                                isSelling: true,
                            }),
                        );
                        dispatch(
                            modalActions.setModalOpen(MODAL_OPEN.BUY_DETAIL),
                        );
                    }}
                >
                    Ir a vender
                </Button>
            </Row>
            <Row>
                {foundMXNCoin && (
                    <div className='linked-text'>
                        <Text
                            color={TEXT_COLOR.PRIMARY}
                            onClick={() => {
                                handleChange(
                                    row.coin + '-text',
                                    'input1',
                                    _getMaxBalance(
                                        foundMXNCoin.id.toString(),
                                    ).toString(),

                                    '',
                                );
                            }}
                        >
                            {`Usar todo (${dotAndComma(
                                _getMaxBalance(
                                    foundMXNCoin.id.toString(),
                                ).toString(),
                            )} MXN)`}
                        </Text>
                    </div>
                )}
            </Row>
        </Form>
    );
};
