import { P, Table } from '@common';
import { Button } from 'axolotl/common';
import { formatISODate } from 'common/Table/utils';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'redux/store';
import {
    getExchangeAdvice,
    setExchangeAdviceDone,
} from 'screens/role-operator/operatorService';
import type { ExchangeAdvice } from 'screens/role-operator/types';
import { formatNumber } from 'utils/string';

export interface ExternalOrdersProps {
    data: ExchangeAdvice[];
    loading: boolean;
    noDataText: string;
}

/*
    "id": 3,
    "asset": "USTD",
    "advice": "SELL",
    "min": "50000.00000000",
    "max": "100000.00000000",
    "active": true,
    "done": false,
    "current_balance": "110000.00000000",
    "created_at": "2022-10-05T19:02:10-03:00",
    "done_at": null,
    "change_state_by": 0,
    "change_state_at": null
*/
export const ExternalOrders = (props: ExternalOrdersProps) => {
    const dispatch = useAppDispatch();

    return (
        <div style={{ overflowX: 'auto' }}>
            <Table
                loading={props.loading}
                noDataText={props.noDataText}
                data={props.data || []}
                columns={[
                    // {
                    //     id: 'id',
                    //     header: '',
                    // },
                    {
                        id: 'createdAt',
                        header: 'Fecha de creación',
                        cell: (value, row) => formatISODate(value),
                    },
                    {
                        id: 'asset',
                        header: 'Moneda',
                    },
                    {
                        id: 'advice',
                        header: 'Tipo de sugerencia',
                        cell: (value, row) => {
                            const color =
                                value === 'BUY' ? 'primary' : 'secondary';
                            return <P color={color}>{value}</P>;
                        },
                    },
                    {
                        id: 'min',
                        header: 'Mínimo',
                        cell: (value, row) =>
                            `${formatNumber(value)} ${row.asset}`,
                    },
                    {
                        id: 'max',
                        header: 'Máximo',
                        cell: (value, row) =>
                            `${formatNumber(value)} ${row.asset}`,
                    },
                    {
                        id: 'doneAt',
                        header: 'Fecha de realizamiento',
                        cell: (value, row) => formatISODate(value),
                    },
                    {
                        id: 'actions',
                        header: '',
                        cell: (cell, row) => {
                            const done = row.done;
                            if (done) return null;
                            return (
                                <Button
                                    onClick={() => {
                                        dispatch(
                                            setExchangeAdviceDone({
                                                id: row.id,
                                                done: true,
                                            }),
                                        )
                                            .unwrap()
                                            .then(() => {
                                                dispatch(getExchangeAdvice());
                                            })
                                            .catch(() => {
                                                toast.error(
                                                    'Hubo un error y no se pido realizar la acción, intentelo nuevamente más tarde.',
                                                );
                                            });
                                    }}
                                    color='primary'
                                >
                                    Realizado
                                </Button>
                            );
                        },
                    },
                ]}
            />
        </div>
    );
};
