import bannerLight from 'axolotl/assets/images/new/Banner-01.png';
import bannerDark from 'axolotl/assets/images/new/Banner-01-dark.png';
import type { MouseEventHandler } from 'react';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Text } from 'axolotl/common';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { dotAndComma, getTotalBalanceEquivalent } from 'utils';
import { getAvatar } from 'screens/role-user/profile/profileService';
import './Header.scss';
import { Svg } from 'axolotl/assets/images/svgs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { layoutActions } from 'layout/layoutSlice';

export const Header = () => {
    const dispatch = useAppDispatch();
    const { mix_background_layout, addMixBackgroundLayout } =
        useContext(ThemeContext);
    const { balances } = useAppSelector((state) => state.common);
    const { avatar } = useAppSelector((state) => state.profile);
    const { sidebarOpen } = useAppSelector((state) => state.layout);
    const totalBalanceEquivalent = getTotalBalanceEquivalent(balances);
    const bannerImage =
        mix_background_layout === 'light-only' ? bannerLight : bannerDark;
    const [dark, setDark] = useState(mix_background_layout === 'dark-only');

    useEffect(() => {
        dispatch(getAvatar());
    }, []);

    useEffect(() => {
        if (dark) {
            document.body.className = 'dark-only';
            addMixBackgroundLayout('dark-only');
        } else {
            document.body.className = 'light-only';
            addMixBackgroundLayout('light-only');
        }
    }, [dark]);

    const handleDarkModeChange = () => {
        const newValue = !dark;
        setDark(newValue);
        if (dark) {
            // dark
            localStorage.setItem('theme_preference', 'light-only');
            document.body.className = 'dark-only';
            addMixBackgroundLayout('dark-only');
        } else {
            // light
            localStorage.setItem('theme_preference', 'dark-only');
            document.body.className = 'light-only';
            addMixBackgroundLayout('light-only');
        }
    };

    return (
        <header>
            <img src={bannerImage} alt='' />
            <div className='floating-content'>
                <div className='buttons'>
                    <div
                        className='bars-toggle'
                        onClick={() =>
                            dispatch(layoutActions.setSidebarOpen(!sidebarOpen))
                        }
                    >
                        <FontAwesomeIcon icon={faBars} color='white' />
                    </div>
                    <Svg.MoonLight onClick={handleDarkModeChange} />
                </div>
                <div className='user-bubble'>
                    <img src={avatar} alt='' />
                    <div className='balances'>
                        <Text className='balance'>
                            {`${dotAndComma(
                                totalBalanceEquivalent.amountUSD.toFixed(2),
                            )} USDT`}
                        </Text>
                        <Text className='equivalent-balance'>
                            {`≈ ${dotAndComma(
                                totalBalanceEquivalent.amountMXN.toFixed(3),
                            )} MXN`}
                        </Text>
                    </div>
                </div>
            </div>
        </header>
    );
};
