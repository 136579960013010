import logo from 'axolotl/assets/images/logo.png';
import { Button, Text } from 'axolotl/common';
import { useNavigate } from 'react-router-dom';
import topBackground from 'axolotl/assets/images/landing-top-banner.png';
import { useResize } from 'utils/hooks';
import { Facebook, Instagram, Linkedin, Twitter, Youtube } from 'react-feather';
import { ROUTE_PATH } from 'layout/routes/const';
import './Landing.scss';
import imageCard1 from 'axolotl/assets/images/new/Landing 01.png';
import imageCard2 from 'axolotl/assets/images/new/Landing 02.png';
import imageCard3 from 'axolotl/assets/images/new/Landing 03.png';

export const Landing = () => {
    return (
        <div className='landing'>
            <Header />
            <div className='content'>
                <Top />
                <Middle />
                <Bottom />
            </div>
        </div>
    );
};

const Header = () => {
    const navigate = useNavigate();

    return (
        <div className='header'>
            <img src={logo} alt='' />
            <div className='link-container'>
                {/* <Button onClick={() => null}>Criptomonedas</Button> */}
                {/* <Button onClick={() => null}>Cotización</Button> */}
                <Button color='dark' onClick={() => navigate(ROUTE_PATH.LOGIN)}>
                    Iniciar sesión
                </Button>
                <Button onClick={() => navigate(ROUTE_PATH.REGISTER)}>
                    Registrate
                </Button>
            </div>
        </div>
    );
};
const Top = () => {
    const navigate = useNavigate();
    const windowSize = useResize();

    const getTitleFontSize = () => {
        if (windowSize.width < 900) return windowSize.width * 0.04;
        if (windowSize.width > 1500) return 53;
        return windowSize.width * 0.035;
    };

    return (
        <div className='top'>
            <img src={topBackground} alt='' />
            <div className='floating-card'>
                <Text title style={{ fontSize: getTitleFontSize() }}>
                    <strong>Descubre</strong> el futuro de la{' '}
                    <strong>economía digital</strong>
                </Text>
                <Button onClick={() => navigate('/register')}>Empezar</Button>
            </div>
        </div>
    );
};

interface ImageCardProps {
    src?: string;
    title: string;
    subtitle: string;
}
const ImageCard = ({ src, title, subtitle }: ImageCardProps) => {
    return (
        <div className='image-card'>
            <img src={src} alt='' />
            <Text className='title'>{title}</Text>
            <Text className='subtitle'>{subtitle}</Text>
        </div>
    );
};
const texts = [
    {
        src: imageCard1,
        title: 'Deposita pesos',
        subtitle:
            'Fondea tu cuenta por transferencia bancaria, Mercado Pago o en efectivo',
    },
    {
        src: imageCard2,
        title: 'Compra crypto',
        subtitle: 'Accede a bitcoins y otros activos digitales en pocos pasos',
    },
    {
        src: imageCard3,
        title: 'Gestiona tus fondos',
        subtitle:
            'Guarda y envia tus cripto o retira tus fondos cuando quieras',
    },
];
const Middle = () => {
    return (
        <div className='middle'>
            <div className='texts'>
                <Text title className='title'>
                    ¿Qué puedes hacer en <strong>Axolotl</strong>?
                </Text>
                <Text className='subtitle' color='gray'>
                    Tenemos un producto para cada una de tus necesidades
                    financieras
                </Text>
            </div>
            <div className='images-cards'>
                {texts.map((textProps) => (
                    <ImageCard
                        key={`image-card-${textProps.title}`}
                        {...textProps}
                    />
                ))}
            </div>
        </div>
    );
};

const linksData = [
    {
        title: 'Productos',
        links: [
            { text: 'Criptomonedas', link: '/' },
            { text: 'Wallet', link: '/' },
            { text: 'Exchange', link: '/' },
            { text: 'Ripio Select', link: '/' },
            { text: 'Swap', link: '/' },
            { text: 'B2B', link: '/' },
        ],
    },
    {
        title: 'Útiles',
        links: [
            { text: 'Launchpad', link: '/' },
            { text: 'Cómo comprar', link: '/' },
            { text: 'Medios de pago', link: '/' },
            { text: 'Seguridad', link: '/' },
            { text: 'Ayuda', link: '/' },
        ],
    },
    {
        title: 'Institucional',
        links: [
            { text: 'Nosotros', link: '/' },
            { text: 'Trabaja con nosotros', link: '/' },
            { text: 'Términos y condiciones', link: '/' },
            { text: 'Politicas de Privacidad', link: '/' },
        ],
    },
];
const Bottom = () => {
    const navigate = useNavigate();
    return (
        <div className='bottom'>
            <div className='top-content'>
                <div className='title-and-button'>
                    <Text>Descubre la nueva economía digital.</Text>
                    <Button onClick={() => navigate('/register')}>
                        Registrate
                    </Button>
                    <Button onClick={() => navigate('/login')} outline>
                        Iniciar Sesión
                    </Button>
                </div>
                <div className='link-columns'>
                    {linksData.map((columnData) => (
                        <div
                            key={`column-data-${columnData.title}`}
                            className='column'
                        >
                            <Text className='title'>{columnData.title}</Text>
                            {columnData.links.map((linkData, i) => (
                                <Text
                                    key={`link-${linkData.link}-${i}`}
                                    className='link'
                                    onClick={() => navigate(linkData.link)}
                                >
                                    {linkData.text}
                                </Text>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
            <div className='social'>
                <Facebook size={28} onClick={() => null} />
                <Instagram size={28} onClick={() => null} />
                <Twitter size={28} onClick={() => null} />
                <Linkedin size={28} onClick={() => null} />
                <Youtube size={28} onClick={() => null} />
            </div>
        </div>
    );
};
