import { Button } from 'axolotl/common';
import { SessionCard } from 'axolotl/screens/session/SessionCard';
import { ROUTE_PATH } from 'layout/routes/const';
import { useNavigate } from 'react-router-dom';
import { Row } from 'reactstrap';

export const RegisterThanks = () => {
    const navigate = useNavigate();

    return (
        <SessionCard.Imageless
            title='Gracias por registrarte!'
            subtitle='Vas a estar recibiendo un email para activar la cuenta.'
        >
            <Row className='justify-content-center'>
                <Button
                    type='submit'
                    style={{ width: 300, marginTop: 50 }}
                    onClick={() => navigate(ROUTE_PATH.LOGIN)}
                >
                    Redirigir al login
                </Button>
            </Row>
        </SessionCard.Imageless>
    );
};
