import { H6, P } from 'common';
import type { CSSProperties } from 'react';
import { Col, Row } from 'reactstrap';
import './PreferenceItem.scss';
import { Button } from 'axolotl/common';

export interface PreferenceItemTexts {
    title: string;
    subTitle: string;
    buttonText: string;
}

interface PreferenceItemProps extends PreferenceItemTexts {
    onClick: () => void;
    style?: CSSProperties;
}

export const PreferenceItem = (props: PreferenceItemProps) => {
    return (
        <Row style={{ padding: '40px', ...props.style }}>
            <Col md='8' sm='12'>
                <H6>{props.title}</H6>
                <P>{props.subTitle}</P>
            </Col>
            <Col md='4' sm='12' className='btn-column'>
                <Button onClick={props.onClick} style={{ width: 180 }}>
                    {props.buttonText}
                </Button>
            </Col>
        </Row>
    );
};
