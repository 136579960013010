import { useAppDispatch, useAppSelector } from 'redux/store';
import { RefreshCw } from 'react-feather';
import { useFormik } from 'formik';
import {
    getBalance,
    getCoins,
    getTokenPairPrices,
    getTokenPairs,
} from 'common/commonService';
import { ORDER_SIDE } from '../../quotation/types';
import { Card, H5, Input, P, TextDropdown } from '@common';
import type { TextDropdownOption } from '@common';
import { CoinComponentObject } from 'common/helper';
import { convertCryptoUtils, dotAndComma } from 'utils/currency';
import Big from 'big.js';
import { Col, Form, Row } from 'reactstrap';
import { addExchangeQuote } from '../../quotation/quotationService';
import { toast } from 'react-toastify';
import { modalActions } from 'common/Modal/modalSlice';
import { MODAL_OPEN } from 'common/Modal/const';
import { useEffect, useState } from 'react';
import './ConvertCrypto.scss';
import { REQUEST_STATE } from '../../../../redux/types';
import { Button, Text } from 'axolotl/common';
import classNames from 'classnames';
import bannerImage from 'axolotl/assets/images/convertir-crypto-banner.png';
import { quotationActions } from 'screens/role-user/quotation/quotationSlice';
import type { COIN_TYPES } from 'utils/const';

export interface ConvertCryptosValues {
    amountFrom: string;
    coinFrom: string;
    coinTo: string;
}

export const ConvertCryptos = () => {
    const dispatch = useAppDispatch();
    const [switchCoin, setSwitchCoin] = useState(false);
    const { coins, balances, tokenPairPrices, tokenPairs } = useAppSelector(
        (state) => state.common,
    );
    const { coins: coinsReq, tokenPairPrices: tppReq } = useAppSelector(
        (state) => state.common.requestStates,
    );
    const [options, setOptions] = useState<TextDropdownOption[]>([]);

    const formik = useFormik<ConvertCryptosValues>({
        initialValues: {
            amountFrom: '',
            coinFrom: '',
            coinTo: '',
        },
        onSubmit: (values) => {
            const equivalentTo = convertCryptoUtils.getToAmountEquivalent(
                coins,
                tokenPairPrices,
                {
                    fromCoinId: values.coinFrom,
                    toCoinId: values.coinTo,
                    fromAmount: values.amountFrom,
                },
            );
            const toCoin = coins?.find(
                (c) => c.id.toString() === values.coinTo,
            );
            const fromCoin = coins?.find(
                (c) => c.id.toString() === values.coinFrom,
            );
            const foundTP = tokenPairs?.find(
                (tp) =>
                    (tp.quoteCode === fromCoin?.tokenCode &&
                        tp.baseCode === toCoin?.tokenCode) ||
                    (tp.baseCode === fromCoin?.tokenCode &&
                        tp.quoteCode === toCoin?.tokenCode),
            );
            if (!fromCoin || !toCoin || !foundTP) return;
            dispatch(
                addExchangeQuote({
                    amount: values.amountFrom,
                    tokenpair_id: foundTP.id,
                    order_side: equivalentTo.type,
                    amount_in:
                        equivalentTo.type === ORDER_SIDE.BUY
                            ? foundTP.quoteCode
                            : foundTP.baseCode,
                    symbol: foundTP.symbol,
                    executeAfterConfirm: null,
                }),
            )
                .unwrap()
                .then(() => {
                    console.log(toCoin);
                    dispatch(
                        quotationActions.setBuySellCoinValues({
                            cryptoCoin:
                                equivalentTo.type === ORDER_SIDE.SELL
                                    ? (fromCoin.tokenCode as COIN_TYPES)
                                    : (toCoin.tokenCode as COIN_TYPES),
                            isSelling: equivalentTo.type === ORDER_SIDE.SELL,
                        }),
                    );
                    dispatch(modalActions.setModalOpen(MODAL_OPEN.BUY_CONFIRM));
                })
                .catch(() => {
                    toast.error(
                        'No se pudo realizar la operación, contacte el administrador e intente denuevo.',
                    );
                });
        },
    });

    useEffect(() => {
        dispatch(getCoins());
        dispatch(getBalance());
        dispatch(getTokenPairPrices());
        dispatch(getTokenPairs());
    }, []);

    useEffect(() => {
        setOptions(
            convertCryptoUtils.getFromOptions(
                coins,
                tokenPairPrices,
                true,
                formik.values.coinTo,
            ),
        );
    }, [formik.values.coinTo, coins, tokenPairPrices]);

    const _getMaxBalance = (fromCoinId: string) =>
        convertCryptoUtils.getMaxAmount(balances, fromCoinId);
    const _limitAmount = (fromCoinId: string, value: string): string => {
        if (!balances) return '';
        const maxBalance = _getMaxBalance(fromCoinId);
        if (!value || maxBalance.eq(Big(0))) return '';
        if (Big(value).gt(maxBalance)) return maxBalance.toString();
        if (Big(value).lt(Big(0))) return '0';
        return value;
    };
    const _getSrcFromCoinId = (
        id: string,
    ): React.FunctionComponent<React.SVGAttributes<SVGElement>> | undefined => {
        if (!coins) return undefined;
        const foundCoin = coins.find((c) => c.id.toString() === id);
        if (!foundCoin) return undefined;
        const res = CoinComponentObject[foundCoin.tokenCode].component;
        return res;
    };
    const handleFromAmountChange = (value: string) => {
        const foundCoin = coins?.find(
            (c) => c.id.toString() === formik.values.coinFrom,
        );
        if (!coins || !foundCoin) return;
        formik.setFieldValue(
            'amountFrom',
            _limitAmount(foundCoin.id.toString(), value),
        );
    };
    const renderUseAllBalance = () => {
        const foundCoin = coins?.find(
            (c) => c.id.toString() === formik.values.coinFrom,
        );
        if (!formik.values.coinFrom || !coins || !foundCoin) return null;
        const max = _getMaxBalance(foundCoin.id.toString());
        return (
            <Text
                color='primary'
                className='use-all-balance-text'
                onClick={() =>
                    formik.setFieldValue('amountFrom', max.toString())
                }
            >
                {`Usar todo mi saldo (${dotAndComma(max.toString())} ${
                    foundCoin.tokenCode
                })`}
            </Text>
        );
    };

    const equivalentAmountData = convertCryptoUtils.getToAmountEquivalent(
        coins,
        tokenPairPrices,
        {
            fromCoinId: formik.values.coinFrom,
            toCoinId: formik.values.coinTo,
            fromAmount: formik.values.amountFrom,
        },
    );
    const handleClickSwitchButton = () => {
        if (formik.values.coinTo && formik.values.coinFrom) {
            if (
                Big(equivalentAmountData.value).gt(
                    _getMaxBalance(formik.values.coinTo),
                )
            ) {
                formik.setFieldValue(
                    'amountFrom',
                    _getMaxBalance(formik.values.coinTo).toString(),
                );
            } else {
                formik.setFieldValue('amountFrom', equivalentAmountData.value);
            }
            setSwitchCoin(!switchCoin);
            formik.setFieldValue('coinFrom', formik.values.coinTo);
            formik.setFieldValue('coinTo', formik.values.coinFrom);
        }
    };
    return (
        <Card noPadding noOverflow className='wallet-convert-cryptos'>
            <img src={bannerImage} alt='' />
            <Form onSubmit={formik.handleSubmit}>
                <Col>
                    <Row className='title-container'>
                        <Text className='title'>Convertir criptomonedas</Text>
                    </Row>
                </Col>
                <Col>
                    <Row>
                        <Col className='from-row'>
                            <div className='input-container'>
                                <Text className='prefix-text'>De</Text>
                                <Input
                                    type='number'
                                    value={formik.values.amountFrom}
                                    className='input-amount'
                                    style={{
                                        textAlign: 'left',
                                        fontSize: '20px',
                                        paddingLeft: '10px',
                                        minWidth: '200px',
                                    }}
                                    onChange={(e) =>
                                        handleFromAmountChange(e.target.value)
                                    }
                                    placeholder='0,00000000'
                                />
                                {/* <Text className='equivalent'>=$0.0</Text> */}
                            </div>
                            {renderUseAllBalance()}
                        </Col>
                        <Col className='text-dropdown-container'>
                            <TextDropdown
                                loading={
                                    coinsReq === REQUEST_STATE.LOADING ||
                                    tppReq === REQUEST_STATE.LOADING
                                }
                                noDataText='No hay monedas disponibles'
                                options={options}
                                value={formik.values.coinFrom}
                                component={_getSrcFromCoinId(
                                    formik.values.coinFrom,
                                )}
                                onChange={(option) => {
                                    if (
                                        option.id !== formik.values.coinFrom &&
                                        option.id !== formik.values.coinTo
                                    ) {
                                        formik.setFieldValue(
                                            'coinFrom',
                                            option.id,
                                        );
                                        formik.setFieldValue('amountFrom', '');
                                    }
                                }}
                                showBorder
                                narrowBorder
                            />
                        </Col>
                    </Row>
                </Col>
                <div className='middle-divider'>
                    <div
                        className={classNames('switch-coin-button', {
                            disabled:
                                !formik.values.coinFrom ||
                                !formik.values.coinTo,
                        })}
                        onClick={handleClickSwitchButton}
                    >
                        <RefreshCw size='24' />
                    </div>
                </div>
                <Col>
                    <Row>
                        <Col className='to-row'>
                            <div className='input-container'>
                                <Text className='prefix-text'>A</Text>
                                <Text className='equivalent-to'>
                                    {dotAndComma(
                                        equivalentAmountData.value.toString(),
                                    )}
                                </Text>
                            </div>
                        </Col>
                        <Col className='text-dropdown-container'>
                            <TextDropdown
                                loading={
                                    coinsReq === REQUEST_STATE.LOADING ||
                                    tppReq === REQUEST_STATE.LOADING
                                }
                                noDataText='No hay monedas disponibles'
                                options={convertCryptoUtils.getToOptions(
                                    coins,
                                    tokenPairPrices,
                                    formik.values.coinFrom,
                                    true,
                                )}
                                value={formik.values.coinTo}
                                component={_getSrcFromCoinId(
                                    formik.values.coinTo,
                                )}
                                onChange={(option) => {
                                    if (option.id !== formik.values.coinTo)
                                        formik.setFieldValue(
                                            'coinTo',
                                            option.id,
                                        );
                                }}
                                showBorder
                                narrowBorder
                            />
                        </Col>
                    </Row>
                    <Row className='button-container'>
                        <Button type='submit'>Convertir</Button>
                    </Row>
                </Col>
            </Form>
        </Card>
    );
};
