import { Text } from 'axolotl/common';
import { MODAL_OPEN } from 'common/Modal/const';
import { useAppDispatch, useAppSelector } from 'redux/store';
import './Receipt.scss';
import { BuyModal } from 'common/Modal/BuyModal/BuyModal';
import { formatNumberWithCommas } from 'utils/string';
import { ORDER_SIDE } from '../../types';
import Big from 'big.js';
import { format, parseISO } from 'date-fns';
import { getBuyAndSellPrices } from 'utils';
import { useMemo } from 'react';
import { modalActions } from 'common/Modal/modalSlice';

export const Receipt = () => {
    const dispatch = useAppDispatch();
    const { confirmedExchangeQuote } = useAppSelector(
        (state) => state.quotation,
    );
    const { tokenPairPrices } = useAppSelector((state) => state.common);
    const { modalOpen } = useAppSelector((state) => state.modal);

    const {
        youBoughtSoldText,
        mainValue,
        eqValue,
        transactionIdText,
        createdAtDate,
        quotationText,
        amountText,
        comissionText,
        totalText,
    } = useMemo(() => {
        const result = {
            youBoughtSoldText: '',
            mainValue: '',
            eqValue: '',
            transactionIdText: '',
            createdAtDate: '',
            quotationText: '',
            amountText: '',
            comissionText: '',
            totalText: '',
        };
        const foundTPP = tokenPairPrices?.find(
            (tpp) => tpp.tokenpairId === confirmedExchangeQuote?.tokenpairId,
        );
        if (!confirmedExchangeQuote || !tokenPairPrices || !foundTPP)
            return result;
        const { orderSide, amount } = confirmedExchangeQuote;
        const prices = getBuyAndSellPrices(
            foundTPP,
            amount,
            confirmedExchangeQuote.priceQuote,
        );

        const isARSorUSDT =
            foundTPP.baseCode === 'MXN' || foundTPP.baseCode === 'USDT';

        result.transactionIdText = `ID de la transacción: ${confirmedExchangeQuote.id.toString()}`;
        result.createdAtDate = format(
            parseISO(confirmedExchangeQuote.createdAt),
            'dd MMM yyyy - HH:mm:ss',
        );
        result.quotationText = `1 ${foundTPP.baseCode} = 
        ${formatNumberWithCommas(
            confirmedExchangeQuote.priceQuote,
            foundTPP.quoteCode,
        )}`;
        if (orderSide === ORDER_SIDE.BUY) {
            result.youBoughtSoldText = `Compraste ${foundTPP.baseCode}`;
            result.mainValue =
                // if coinType is MXN or USDT, show the amount with 2 decimals, else show it with 8 decimals
                isARSorUSDT
                    ? formatNumberWithCommas(
                          prices.buy.toString(),
                          foundTPP.baseCode,
                          true,
                      ) +
                      ' ' +
                      foundTPP.baseCode
                    : formatNumberWithCommas(
                          prices.buy.toFixed(8),
                          foundTPP.baseCode,
                      );
            result.eqValue = result.totalText = isARSorUSDT
                ? formatNumberWithCommas(amount, foundTPP.quoteCode, true) +
                  ' ' +
                  foundTPP.quoteCode
                : formatNumberWithCommas(amount, foundTPP.quoteCode);
            result.amountText = isARSorUSDT
                ? formatNumberWithCommas(
                      Big(amount)
                          .minus(Big(confirmedExchangeQuote.fee))
                          .toString(),
                      foundTPP.quoteCode,
                      true,
                  ) +
                  ' ' +
                  foundTPP.quoteCode
                : formatNumberWithCommas(
                      Big(amount)
                          .minus(Big(confirmedExchangeQuote.fee))
                          .toString(),
                      foundTPP.quoteCode,
                  );
            result.comissionText = isARSorUSDT
                ? formatNumberWithCommas(
                      confirmedExchangeQuote.fee,
                      foundTPP.quoteCode,
                      true,
                  ) +
                  ' ' +
                  foundTPP.quoteCode
                : formatNumberWithCommas(
                      confirmedExchangeQuote.fee,
                      foundTPP.quoteCode,
                  );
        }
        if (orderSide === ORDER_SIDE.SELL) {
            result.youBoughtSoldText = `Vendiste ${foundTPP.baseCode}`;
            result.mainValue = isARSorUSDT
                ? formatNumberWithCommas(amount, foundTPP.baseCode, true) +
                  ' ' +
                  foundTPP.baseCode
                : formatNumberWithCommas(amount, foundTPP.baseCode);
            result.eqValue = isARSorUSDT
                ? formatNumberWithCommas(
                      prices.sell.toString(),
                      foundTPP.quoteCode,
                      true,
                  ) +
                  ' ' +
                  foundTPP.quoteCode
                : formatNumberWithCommas(
                      prices.sell.toString(),
                      foundTPP.quoteCode,
                  );
            result.totalText = isARSorUSDT
                ? formatNumberWithCommas(amount, foundTPP.baseCode, true) +
                  ' ' +
                  foundTPP.baseCode
                : formatNumberWithCommas(amount, foundTPP.baseCode);
            result.amountText = isARSorUSDT
                ? formatNumberWithCommas(
                      Big(amount)
                          .minus(Big(confirmedExchangeQuote.fee))
                          .toString(),
                      foundTPP.baseCode,
                      true,
                  ) +
                  ' ' +
                  foundTPP.baseCode
                : formatNumberWithCommas(
                      Big(amount)
                          .minus(Big(confirmedExchangeQuote.fee))
                          .toString(),
                      foundTPP.baseCode,
                  );
            result.comissionText = isARSorUSDT
                ? formatNumberWithCommas(
                      confirmedExchangeQuote.fee,
                      foundTPP.baseCode,
                      true,
                  ) +
                  ' ' +
                  foundTPP.baseCode
                : formatNumberWithCommas(
                      confirmedExchangeQuote.fee,
                      foundTPP.baseCode,
                  );
        }
        return result;
    }, [confirmedExchangeQuote]);

    return (
        <BuyModal
            className='receipt-modal'
            open={modalOpen === MODAL_OPEN.BUY_RECEIPT}
            onClose={() => dispatch(modalActions.setModalOpen(MODAL_OPEN.NONE))}
        >
            <div className='title-container'>
                <Text className='title'>{youBoughtSoldText}</Text>
                <div className='result-value-box'>{mainValue}</div>
                <Text className='equivalent'>{`= ${eqValue}`}</Text>
            </div>
            <div className='details-container'>
                <Text className='transaction-id'>{transactionIdText}</Text>
                <div className='group-text'>
                    <Text>Estado de transacción</Text>
                    <Text style={{ color: '#f24620' }}>Completada</Text>
                </div>
                <div className='group-text'>
                    <Text>Fecha de creación</Text>
                    <Text>{createdAtDate}</Text>
                </div>
                <div className='group-text'>
                    <Text>Cotización</Text>
                    <Text>{quotationText}</Text>
                </div>
                <div className='divider-gray' />
                <div className='group-text'>
                    <Text>Monto</Text>
                    <Text>{amountText}</Text>
                </div>
                <div className='group-text'>
                    <Text>Comisión</Text>
                    <Text>{comissionText}</Text>
                </div>
                <div className='divider-orange' />
                <div className='group-text total-orange'>
                    <Text>Total</Text>
                    <Text>{totalText}</Text>
                </div>
            </div>
        </BuyModal>
    );
};
