import { combineReducers } from '@reduxjs/toolkit';
import { modalReducer } from '../common/Modal/modalSlice';
import { layoutReducer } from '../layout/layoutSlice';
import { sessionReducer } from 'screens/session/sessionSlice';
import { commonReducer } from 'common/commonSlice';
import { quotationReducer } from 'screens/role-user/quotation/quotationSlice';
import { profileReducer } from 'screens/role-user/profile/profileSlice';
import { operatorReducer } from 'screens/role-operator/operatorSlice';
import { marketReducer } from 'screens/role-user/market/marketSlice';
import { walletReducer } from 'screens/role-user/wallet/walletSlice';

export const rootReducer = combineReducers({
    modal: modalReducer,
    layout: layoutReducer,
    profile: profileReducer,
    session: sessionReducer,
    common: commonReducer,
    quotation: quotationReducer,
    operator: operatorReducer,
    market: marketReducer,
    wallet: walletReducer,
});
