import { FormInput } from 'common';
import { useContext, useEffect, useState } from 'react';
import { Col, Form, Row } from 'reactstrap';
import type { UserData } from '../types';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getCountries } from 'common/commonService';
import { getSelectOptionFromCountries } from 'common/FormInput/utils';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ThemeContext } from '../../../../providers/ThemeProvider';
import { Button, Text } from 'axolotl/common';

export interface SettingsPersonalDataValuesSubmit {
    phoneCode: string;
    phoneNumber: string;
    password: string;
}

export interface SettingsPersonalDataValues {
    phone: string;
    password: string;
}

const getValidationSchema = (savedPhone: string) => {
    return Yup.object().shape({
        name: Yup.string().required('Este campo es requerido'),
        surname: Yup.string().required('Este campo es requerido'),
        country: Yup.string().required('Este campo es requerido'),
        cuit: Yup.string().required('Este campo es requerido'),
        phone: Yup.string()
            .required('El número es requerido')
            .test(
                'phone',
                'El número de teléfono no puede ser igual al anterior',
                (value) => value !== savedPhone,
            ),
        password: Yup.string().required('La contraseña es requerida'),
    });
};

interface SettingsPersonalDataProps {
    onSubmit: (values: SettingsPersonalDataValuesSubmit) => void;
    disabled: boolean;
    data: UserData | null;
    loading: boolean;
}

export const SettingsPersonalData = (props: SettingsPersonalDataProps) => {
    const dispatch = useAppDispatch();
    const { countries } = useAppSelector((state) => state.common);
    const [phoneData, setPhoneData] = useState({ code: '', phone: '' });
    const [requestChangesMode, setRequestChangesMode] = useState(false);
    const theme = useContext(ThemeContext);

    const getClassName = () => {
        let result = 'profile-phone-input';
        if (theme.mix_background_layout === 'dark-only') result += ' dark';
        return result;
    };

    const savedPhone = props.data
        ? props.data.phone_country_cod.toString() +
          props.data.phone_number.toString()
        : '';

    const formik = useFormik({
        initialValues: {
            name: '',
            surname: '',
            cuit: '',
            country: '',
            phone: '',
            password: '',
        },
        onSubmit: (values) => {
            props.onSubmit({
                ...values,
                phoneCode: phoneData.code,
                phoneNumber: phoneData.phone,
            });
            setRequestChangesMode(false);
            formik.setFieldValue('password', '');
        },
        validationSchema: getValidationSchema(savedPhone),
    });

    useEffect(() => {
        dispatch(getCountries());
    }, []);

    useEffect(() => {
        if (props.data) {
            formik.setValues(
                {
                    ...formik.values,
                    name: props.data.name,
                    surname: props.data.surname,
                    cuit: props.data.cuit,
                    country: props.data.country_id.toString(),
                    phone: props.data
                        ? props.data.phone_country_cod.toString() +
                          props.data.phone_number.toString()
                        : '',
                },
                false,
            );
        }
    }, [props.data]);

    // if requestChangesMode is false and formik is not submitting, the code and phone number are the saved ones
    useEffect(() => {
        if (!requestChangesMode && !formik.isSubmitting) {
            formik.setValues(
                {
                    ...formik.values,
                    phone: savedPhone,
                },
                false,
            );
        }
    }, [requestChangesMode, formik.isSubmitting]);

    return (
        <div className='user-settings-personal-data-container'>
            <Text className='title'>Datos personales</Text>
            <Text>
                Esta informacion solo esta disponible accediento a tu cuenta.
                Tus datos personales no van a ser revelados a terceros.
            </Text>
            <Form
                style={{ marginTop: 25 }}
                className='needs-validation'
                onSubmit={formik.handleSubmit}
            >
                <Row className='d-flex flex-wrap'>
                    <Col style={{ minWidth: 300 }}>
                        <FormInput
                            label='Nombre'
                            name='name'
                            onChange={() => null}
                            onFormikChange={formik.handleChange}
                            value={formik.values.name}
                            disabled
                        />
                    </Col>
                    <Col style={{ minWidth: 300 }}>
                        <FormInput
                            label='CUIT'
                            name='cuit'
                            type='cuit'
                            onChange={() => null}
                            onFormikChange={formik.handleChange}
                            value={formik.values.cuit}
                            disabled
                        />
                    </Col>
                </Row>
                <Row>
                    <Col style={{ minWidth: 300 }}>
                        <FormInput
                            label='Apellido'
                            name='surname'
                            onChange={() => null}
                            onFormikChange={formik.handleChange}
                            value={formik.values.surname}
                            disabled
                        />
                    </Col>
                    <Col style={{ minWidth: 300 }}>
                        <FormInput
                            label='País'
                            name='country'
                            type='select'
                            options={getSelectOptionFromCountries(countries)}
                            onChange={() => null}
                            onFormikChange={formik.handleChange}
                            value={formik.values.country}
                            disabled
                        />
                    </Col>
                </Row>
                <Row>
                    <Col
                        style={{
                            minWidth: 300,
                        }}
                    >
                        <div className={getClassName()}>
                            <FormInput
                                label='Teléfono'
                                name='phone'
                                type='tel'
                                value={formik.values.phone}
                                onChange={() => null}
                                onFormikPhoneChange={(e, data) => {
                                    formik.handleChange(e);
                                    setPhoneData(data);
                                }}
                                error={
                                    requestChangesMode
                                        ? formik.errors.phone
                                        : undefined
                                }
                                disabled={!requestChangesMode}
                            />
                        </div>
                    </Col>
                    <Col style={{ minWidth: 300 }}>
                        {requestChangesMode ? (
                            <FormInput
                                label='Contraseña'
                                type='password'
                                name='password'
                                onFormikChange={formik.handleChange}
                                onChange={() => null}
                                value={formik.values.password}
                                error={formik.errors.password}
                            />
                        ) : null}
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col className='d-flex flex-row-reverse'>
                        {requestChangesMode ? (
                            <Row>
                                <Button
                                    color='danger'
                                    style={{ width: 150, marginRight: 10 }}
                                    onClick={() => {
                                        setRequestChangesMode(false);
                                        formik.setFieldValue('password', '');
                                    }}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    type='submit'
                                    style={{ width: 150 }}
                                    loading={props.loading}
                                >
                                    Aceptar
                                </Button>
                            </Row>
                        ) : (
                            <Button
                                style={{ width: 200 }}
                                onClick={() => {
                                    setRequestChangesMode(true);
                                }}
                            >
                                Solicitar cambios
                            </Button>
                        )}
                    </Col>
                </Row>
            </Form>
        </div>
    );
};
