import type { CSSProperties } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import './Tabs.scss';

export interface TabItem {
    title: string;
    link?: string;
    active: boolean;
    id?: any;
}

interface TabsProps {
    items: TabItem[];
    onClick?: (id: string) => void | undefined;
    style?: CSSProperties;
}

export const Tabs = (props: TabsProps) => {
    return (
        <div className='axolotl-tabs'>
            {props.items.map((item, i) => (
                <NavItem key={`nav-item-${i}`}>
                    <Link
                        to={item.link ? item.link : ''}
                        className={`nav-link${item.active ? ' active' : ''}`}
                        style={{
                            fontSize: 14,
                            fontWeight: item.active ? 'bold' : '600',
                            textTransform: 'uppercase',
                        }}
                        onClick={() => {
                            if (props.onClick) {
                                props.onClick(item.id);
                            }
                        }}
                    >
                        {item.title}
                    </Link>
                </NavItem>
            ))}
        </div>
    );
};
