import { P, Table } from '@common';
import { getTokenPairPrices } from 'common/commonService';
import { ExchangeOrderStatusText } from 'common/Table/common-cells';
import { formatISODate } from 'common/Table/utils';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { dotAndComma } from 'utils';
import { formatNumber } from 'utils/string';
import type { DashboardOrdersProps } from './OpenOrders';
import {
    getOrderSideText,
    getOrderTypeData,
} from 'screens/role-user/market/OrdersTablesCard/OpenOrdersTableTab';

export const HistoryOrders = (props: DashboardOrdersProps) => {
    const dispatch = useAppDispatch();
    const tokenPairPrices = useAppSelector(
        (state) => state.common.tokenPairPrices,
    );

    useEffect(() => {
        dispatch(getTokenPairPrices());
    }, []);

    return (
        <div style={{ overflowX: 'auto' }}>
            <Table
                loading={props.loading}
                noDataText={props.noDataText}
                data={props.data || []}
                columns={[
                    {
                        id: 'id',
                        header: 'Número de operación',
                    },
                    {
                        id: '__fullname',
                        header: 'Nombre completo',
                        cell: (value, row) => `${row.name} ${row.lastname}`,
                    },
                    {
                        id: 'email',
                        header: 'Email',
                    },
                    {
                        id: 'createdAt',
                        header: 'Fecha de creación',
                        cell: (cell, row) => formatISODate(row.createdAt),
                    },
                    {
                        id: 'tokenpairId',
                        header: 'Par',
                        cell: (cell) => {
                            const foundTPP = tokenPairPrices?.find(
                                (tpp) => tpp.tokenpairId === cell,
                            );
                            if (!tokenPairPrices || !foundTPP) return '';
                            return (
                                <P style={{ fontWeight: 'bold' }}>
                                    {foundTPP.symbolPretty}
                                </P>
                            );
                        },
                    },
                    {
                        id: 'orderType',
                        header: 'Tipo',
                        cell: (cell) => {
                            const orderTypeData = getOrderTypeData(cell);
                            return (
                                <P color={orderTypeData.color}>
                                    {orderTypeData.text}
                                </P>
                            );
                        },
                    },
                    {
                        id: 'orderSide',
                        header: 'Compra/Venta',
                        cell: getOrderSideText,
                    },
                    {
                        id: 'amount',
                        header: 'Cantidad',
                        cell: (value, row) =>
                            `${dotAndComma(formatNumber(value))} ${
                                row.amountIn
                            }`,
                    },
                    {
                        id: 'priceTarget',
                        header: 'Precio Objetivo',
                        cell: (value, row) => {
                            const foundTPP = tokenPairPrices?.find(
                                (tpp) => tpp.tokenpairId === row.tokenpairId,
                            );
                            if (!tokenPairPrices || !foundTPP) return ' ';
                            return `${dotAndComma(formatNumber(value))} ${
                                foundTPP.quoteCode
                            }`;
                        },
                    },
                    {
                        id: 'active-executed',
                        header: 'Estado',
                        cell: (cell, row) => {
                            const { active, executed } = row;
                            return (
                                <ExchangeOrderStatusText
                                    active={active}
                                    executed={executed}
                                />
                            );
                        },
                    },
                ]}
            />
        </div>
    );
};
