import Chart from 'react-apexcharts';
import type { ApexOptions } from 'apexcharts';
import Big from 'big.js';

interface SparklineProps {
    series: string[];
}

export const Sparkline = (props: SparklineProps) => {
    const { series } = props;
    const series1 = [
        {
            data: series.map((n) => Big(n).toNumber()),
        },
    ];

    const getOptions = (): ApexOptions => {
        let yAxisMin = Big(0);
        let yAxisMax = Big(0);
        props.series.forEach((p) => {
            if (Big(p).lt(Big(yAxisMin))) yAxisMin = Big(p);
            if (Big(p).gt(Big(yAxisMax))) yAxisMax = Big(p);
        });
        return {
            chart: {
                type: 'line',
                sparkline: {
                    enabled: true,
                },
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 300,
                },
                dropShadow: {
                    enabled: true,
                    top: 4,
                    left: 0,
                    blur: 4,
                    opacity: 0.4,
                },
            },
            stroke: {
                curve: 'smooth',
                width: 2,
            },
            colors: ['#F37621'],
            markers: {
                size: 0,
                discrete: [
                    {
                        seriesIndex: 0,
                        dataPointIndex: props.series.length - 1,
                        fillColor: '#F37621',
                        strokeColor: '#F37621',
                        size: 4,
                    },
                ],
            },
            tooltip: {
                fixed: {
                    enabled: false,
                },
                x: {
                    show: false,
                },
                y: {
                    title: {
                        formatter: () => '',
                    },
                },
                marker: {
                    show: false,
                },
            },
        };
    };

    return (
        <>
            <Chart
                series={series1}
                width={80}
                height={35}
                options={getOptions()}
            />
        </>
    );
};
