import { P } from 'common/Paragraph';
import './ToastBanner.scss';

interface ToastBannerProps {
    show: boolean;
    message: string;
    className?: string;
}

export const ToastBanner = (props: ToastBannerProps) => {
    const getClassName = () => {
        let result = 'axolotl-toast-banner';
        if (props.show) result += ' show';
        if (props.className) result += ' ' + props.className;
        return result;
    };

    return (
        <div className={getClassName()}>
            <P>{props.message}</P>
        </div>
    );
};
