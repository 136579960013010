import { Loader } from 'common';
import { ThemeContext } from 'providers/ThemeProvider';
import type { CSSProperties, Dispatch, SetStateAction } from 'react';
import { useContext, Suspense, useEffect, useState } from 'react';
import {
    BrowserRouter,
    Navigate,
    Outlet,
    Route,
    Routes as ReactRouterDomRoutes,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { REQUEST_STATE } from 'redux/types';
import { refreshToken } from 'screens/session/sessionService';
import { getUserType } from 'screens/role-user/profile/profileService';
import { USER_TYPE } from 'screens/role-user/profile/types';
import { Confirm } from 'screens/role-user/quotation/buy-flow/Confirm/Confirm';
import { Details } from 'screens/role-user/quotation/buy-flow/Details/Details';
import { Receipt } from 'screens/role-user/quotation/buy-flow/Receipt/Receipt';
import { AUTHENTICATED, REFRESH_TOKEN } from 'utils/axios';
import { Header } from './Header/Header';
import { HelpModal } from './HelpModal';
import { ROUTE_PATH } from './routes/const';
import { operatorRoutes } from './routes/operatorRoutes';
import { sessionRoutes } from './routes/sessionRoutes';
import { userRoutes } from './routes/userRoutes';
import { Sidebar } from './Sidebar';
import { Landing } from 'axolotl/screens/landing';

interface AppLayoutProps {
    children?: JSX.Element;
    classNames?: string[];
    showHelpModal?: boolean;
}

const AppLayout = (props: AppLayoutProps) => {
    const theme = useContext(ThemeContext);
    const location = useLocation();
    const sidebarOpen = useAppSelector((state) => state.layout.sidebarOpen);

    const getClassName = () => {
        let result = 'page-wrapper compact-wrapper';
        if (
            theme.mix_background_layout === 'dark-only' ||
            useLocation().pathname === ROUTE_PATH.MARKET
        )
            result += ' dark-scroll';
        return result;
    };

    const getStyles = (): CSSProperties => {
        if (location.pathname === ROUTE_PATH.MARKET) {
            return {
                overflowY: 'auto',
                maxHeight: 'calc(100vh - 130px)',
                marginLeft: sidebarOpen ? 150 : 0,
                marginTop: 0,
            };
        } else {
            return {
                overflowY: 'auto',
                maxHeight: 'calc(100vh - 220px)',
                marginLeft: sidebarOpen ? 320 : 0,
                marginTop: 0,
            };
        }
    };

    return (
        <div className={getClassName()} id='pageWrapper'>
            <Header />
            <div className='page-body-wrapper horizontal-menu'>
                <Sidebar />
                <div className='page-body' style={getStyles()}>
                    {/* <Confirm />
                    <Receipt />
                    <Details /> */}
                    <Confirm />
                    <Receipt />
                    <Details />
                    <Outlet />
                    <HelpModal show={!!props.showHelpModal} />
                </div>
            </div>
        </div>
    );
};

const RefreshHelper = ({
    setFirstRender,
}: {
    setFirstRender: Dispatch<SetStateAction<boolean>>;
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const refresh = localStorage.getItem(REFRESH_TOKEN);

    useEffect(() => {
        const currentLocation = location;
        if (!!refresh) {
            dispatch(refreshToken({ refresh }))
                .unwrap()
                .then(() => {
                    navigate(currentLocation);
                    dispatch(getUserType());
                })
                .catch(() => null);
        }
        setFirstRender(false);
    }, []);

    return null;
};

export const Routes = () => {
    const [firstRender, setFirstRender] = useState(true);
    const { requestStates } = useAppSelector((state) => state.session);
    const userType = useAppSelector((state) => state.profile.userType);
    const authenticated = localStorage.getItem(AUTHENTICATED) === 'true';
    const refreshState = requestStates.refresh;
    const getAuthenticatedRoutes = () => {
        if (userType === USER_TYPE.USER)
            return { routes: userRoutes, keyPrefix: 'user-route-' };
        if (userType === USER_TYPE.OPERATOR)
            return { routes: operatorRoutes, keyPrefix: 'operator-route-' };
        else {
            return { routes: null, keyPrefix: '' };
        }
    };
    const authenticatedRoutes = getAuthenticatedRoutes();

    return (
        <BrowserRouter>
            <Suspense fallback={<Loader show />}>
                <RefreshHelper setFirstRender={setFirstRender} />
                <Loader
                    show={
                        refreshState === REQUEST_STATE.LOADING ||
                        firstRender ||
                        (!authenticatedRoutes.routes && authenticated)
                    }
                />
                <ReactRouterDomRoutes>
                    <Route
                        path='/'
                        element={
                            authenticated ? (
                                <Navigate to={ROUTE_PATH.DASHBOARD} />
                            ) : (
                                <Landing />
                            )
                        }
                    />
                    {authenticated && (
                        <Route
                            path='/*'
                            element={
                                <ReactRouterDomRoutes>
                                    {authenticatedRoutes.routes?.map(
                                        ({ path, Component }, i) => (
                                            <Route
                                                element={
                                                    <AppLayout showHelpModal />
                                                }
                                                key={
                                                    authenticatedRoutes.keyPrefix +
                                                    i
                                                }
                                            >
                                                <Route
                                                    path={path}
                                                    element={Component}
                                                />
                                            </Route>
                                        ),
                                    )}
                                    {!authenticatedRoutes.routes ||
                                        (authenticatedRoutes.routes.length >
                                            0 && (
                                            <Route
                                                path='*'
                                                element={<Navigate to='/' />}
                                            />
                                        ))}
                                </ReactRouterDomRoutes>
                            }
                        />
                    )}
                    {!authenticated &&
                        sessionRoutes.map(({ path, Component }, i) => (
                            <Route
                                key={`session-route-${i}`}
                                path={path}
                                element={Component}
                            />
                        ))}
                    <Route path='*' element={<Navigate to='/' />} />
                </ReactRouterDomRoutes>
                <ToastContainer
                    position='top-right'
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    pauseOnHover
                />
            </Suspense>
        </BrowserRouter>
    );
};
