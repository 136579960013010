import { P } from 'common';
import {
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown,
} from 'reactstrap';
import { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { useAppSelector } from 'redux/store';
import { MoreHorizontal } from 'react-feather';

interface BankAccountItemProps {
    onEdit: () => void;
    // onDelete: () => void;
}

export const BankAccountItem = (props: BankAccountItemProps) => {
    const theme = useContext(ThemeContext);
    const dropDownItemColor =
        theme.mix_background_layout === 'light-only' ? 'black' : 'white';
    const getClassName = () => {
        let result = 'text-dropdown';
        if (theme.mix_background_layout === 'dark-only') result += ' dark';
        return result;
    };
    const paymentMethod = useAppSelector(
        (state) => state.profile.paymentMethod,
    );

    return (
        <Row
            className='d-flex align-items-center axolotl-bank-account-item'
            style={{
                height: 90,
                boxShadow: '0 4px 30px 0 rgba(127, 127, 127, 0.2)',
            }}
        >
            {paymentMethod ? (
                <Col md='auto' className='pl-5'>
                    <Row>
                        <P style={{ fontWeight: 'bold', marginRight: 10 }}>
                            Alias:
                        </P>
                        <P>{paymentMethod?.alias}</P>
                    </Row>
                    <Row>
                        <P style={{ fontWeight: 'bold', marginRight: 10 }}>
                            CBU:
                        </P>
                        <P>{paymentMethod?.cbu}</P>
                    </Row>
                </Col>
            ) : (
                <Col
                    md='10'
                    className='align-items-center justify-content-center'
                >
                    No hay datos bancarios
                </Col>
            )}
            {paymentMethod && (
                <Col className='d-flex justify-content-end align-items-center'>
                    <UncontrolledDropdown className={getClassName()}>
                        <DropdownToggle
                            tag='div'
                            className='options-dropddown-toggle'
                        >
                            <MoreHorizontal />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={props.onEdit}>
                                <P style={{ color: dropDownItemColor }}>
                                    Editar
                                </P>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Col>
            )}
        </Row>
    );
};
