import { useContext } from 'react';
import { Input, Label, Media } from 'reactstrap';
import { P } from '@common';
import { ThemeContext } from '../../../providers/ThemeProvider';

interface SwitchProps {
    onChange: (value: OPERATOR_ROBOT_SWITCH_STATE) => void;
    state: OPERATOR_ROBOT_SWITCH_STATE;
}

export enum OPERATOR_ROBOT_SWITCH_STATE {
    ON = 'ON',
    OFF = 'OFF',
    NONE = 'NONE',
}

export const OperatorRobotSwitch = (props: SwitchProps) => {
    const theme = useContext(ThemeContext);
    const getClassname = () => {
        let result = 'switch-state';
        if (theme.mix_background_layout === 'dark-only')
            result += ' dark-switch';
        return result;
    };
    console.log(props.state);

    const handleChange = () => {
        console.log(props.state);
        switch (props.state) {
            case OPERATOR_ROBOT_SWITCH_STATE.ON:
                props.onChange(OPERATOR_ROBOT_SWITCH_STATE.OFF);
                break;
            case OPERATOR_ROBOT_SWITCH_STATE.OFF:
                props.onChange(OPERATOR_ROBOT_SWITCH_STATE.ON);
                break;
            case OPERATOR_ROBOT_SWITCH_STATE.NONE:
            default:
                break;
        }
    };

    const getText = () => {
        switch (props.state) {
            case OPERATOR_ROBOT_SWITCH_STATE.ON:
                return 'Robot activo';
            case OPERATOR_ROBOT_SWITCH_STATE.OFF:
                return 'Robot activo';
            case OPERATOR_ROBOT_SWITCH_STATE.NONE:
            default:
                return '';
        }
    };

    return (
        <Media>
            <Media body className='text-end'>
                <Label className='switch'>
                    <Input
                        type='checkbox'
                        onClick={handleChange}
                        checked={props.state === OPERATOR_ROBOT_SWITCH_STATE.ON}
                    />
                    <span className={getClassname()}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '7px',
                            }}
                        >
                            <P>{getText()}</P>
                        </div>
                    </span>
                </Label>
            </Media>
        </Media>
    );
};
