import { SessionCard } from 'axolotl/screens/session/SessionCard';
import loginImage from 'axolotl/assets/images/ilus-log in.png';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { FormInput } from 'common';
import { Lock, Mail } from 'react-feather';
import { Form, Row } from 'reactstrap';
import { Button, Text } from 'axolotl/common';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from 'layout/routes/const';
import { login } from '../sessionService';
import { toast } from 'react-toastify';
import { REQUEST_STATE } from 'redux/types';
import { getUserType } from 'screens/role-user/profile/profileService';
import { sessionActions } from '../sessionSlice';
import {
    getUserPhotoFace,
    getUserPhotoId,
    getUserPhotoSelfie,
} from '../photoUploadService';
import { useState } from 'react';
import { UploadImage } from './UploadImage';

const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('El mail es requerido'),
    password: Yup.string().required('Este campo es requerido'),
});

export const Login = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [renderImg, setRenderImg] = useState(false);
    const loginState = useAppSelector(
        (state) => state.session.requestStates.login,
    );

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema,
        onSubmit: (values) => {
            dispatch(login(values))
                .unwrap()
                .then(() => {
                    dispatch(getUserType())
                        .unwrap()
                        .then((res) => {
                            if (res.usertype === 'operator')
                                navigate(ROUTE_PATH.LOGIN2FA);
                            else {
                                dispatch(sessionActions.clearPhoto());
                                Promise.all([
                                    dispatch(getUserPhotoFace()).unwrap(),
                                    dispatch(getUserPhotoId()).unwrap(),
                                    dispatch(getUserPhotoSelfie()).unwrap(),
                                ])
                                    .then(() => {
                                        navigate(ROUTE_PATH.LOGIN2FA);
                                    })
                                    .catch(() => {
                                        setRenderImg(true);
                                    });
                            }
                        })
                        .catch(() => {
                            toast.error(
                                'Hubo un error, vuelve a intentar más tarde.',
                            );
                        });
                })
                .catch(() =>
                    toast.error('El mail y/o contraseña no son correctos.'),
                );
        },
    });

    if (renderImg) return <UploadImage />;

    return (
        <SessionCard
            title='Ingresar'
            subtitle='Bienvenido, ingresa a tu cuenta'
            image={loginImage}
        >
            <Form className='col' onSubmit={formik.handleSubmit}>
                <Row sm='1'>
                    <FormInput
                        leftIcon={<Mail />}
                        label='Email'
                        name='email'
                        type='email'
                        placeholder='Testmail@gmail.com'
                        onChange={() => null}
                        value={formik.values.email}
                        // disabled={false}
                        error={formik.errors.email}
                        onFormikChange={formik.handleChange}
                    />
                </Row>
                <Row sm='1'>
                    <FormInput
                        leftIcon={<Lock />}
                        label='Contraseña'
                        name='password'
                        type='password'
                        onChange={() => null}
                        value={formik.values.password}
                        // disabled={false}
                        error={formik.errors.password}
                        onFormikChange={formik.handleChange}
                    />
                </Row>
                <Row className='d-flex flex-row-reverse'>
                    <Text onClick={() => navigate(ROUTE_PATH.RECOVER_PASSWORD)}>
                        ¿Olvidaste tu contraseña?
                    </Text>
                </Row>
                <Row className='buttons'>
                    <Button
                        type='submit'
                        loading={loginState === REQUEST_STATE.LOADING}
                    >
                        Ingresar
                    </Button>
                    <div>
                        ¿No tienes una cuenta?{' '}
                        <Text onClick={() => navigate(ROUTE_PATH.REGISTER)}>
                            Crear
                        </Text>
                    </div>
                </Row>
            </Form>
        </SessionCard>
    );
};
