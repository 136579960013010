import { Col, Row } from 'reactstrap';
import { WalletCard } from './WalletCard';
import { TotalBalance } from '../conversor/TotalBalance';

export const Wallet = () => {
    return (
        <div className='m-3'>
            <Row className='mb-4'>
                <Col>
                    <TotalBalance />
                </Col>
            </Row>
            <Row>
                <WalletCard />
            </Row>
        </div>
    );
};
