import { Input } from 'reactstrap';
import './InputAmount.scss';
import { Text } from 'axolotl/common';

interface InputAmountProps {
    value: string;
    onChange: (value: string) => void;
    coinType: string;
    equivalent: string;
}

export const InputAmount = (props: InputAmountProps) => {
    return (
        <div className='input-amount'>
            <Input
                type='number'
                placeholder='0,00'
                value={props.value}
                onChange={(value) => {
                    props.onChange(value.target.value);
                }}
                style={{
                    fontSize: 50,
                }}
            />
            <Text className='amount-text'>Ingresa un monto</Text>
            <Text className='equivalent'>
                {`${props.equivalent || '0.0000000'} ${props.coinType}`}
            </Text>
        </div>
    );
};
