import { H5 } from 'common';
import { Col } from 'reactstrap';
import { QR_STEPS } from './const';
import { Button } from 'axolotl/common';

export const QR_step_1 = ({ goToStep }: any) => {
    return (
        <Col style={{ width: 380 }}>
            <H5
                style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    padding: '0px 70px',
                    marginBottom: 40,
                }}
            >
                Gestionar autentificación de dos factores
            </H5>
            <Button
                style={{
                    margin: '10px 0px 0px 60px',
                    width: '70%',
                    fontSize: '13px',
                }}
                onClick={() => {
                    goToStep(QR_STEPS.QR_STEP_2);
                }}
            >
                Generar nuevo código QR
            </Button>
        </Col>
    );
};
