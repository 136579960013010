import { ReactComponent as HelpOrange } from './ayuda-naranja.svg';
import { ReactComponent as HelpBrown } from './ayuda-marron.svg';
import { ReactComponent as HelpDark } from './ayuda-dark.svg';
import { ReactComponent as Wallet } from './Billetera.svg';
import { ReactComponent as WalletOrange } from './Billetera-naranja.svg';
import { ReactComponent as WalletBrown } from './Billetera-marron.svg';
import { ReactComponent as WalletDark } from './Billetera-dark.svg';
import { ReactComponent as QuotationOrange } from './Cotizaciones-naranja.svg';
import { ReactComponent as QuotationBrown } from './Cotizaciones-marron.svg';
import { ReactComponent as QuotationDark } from './Cotizaciones-dark.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as HistoryOrange } from './Historial-naranja.svg';
import { ReactComponent as HistoryBrown } from './Historial-marron.svg';
import { ReactComponent as HistoryDark } from './Historial-dark.svg';
import { ReactComponent as LogoutOrange } from './log-out-naranja.svg';
import { ReactComponent as LogoutBrown } from './log-out-marron.svg';
import { ReactComponent as LogoutDark } from './log-out-dark.svg';
import { ReactComponent as MoonLight } from './luna-light.svg';
import { ReactComponent as MoonDark } from './Luna-dark.svg';
import { ReactComponent as GlassOrange } from './lupa-naranja.svg';
import { ReactComponent as GlassBrown } from './lupa-marron.svg';
import { ReactComponent as GlassDark } from './lupa-dark.svg';
import { ReactComponent as NotificationsLight } from './Notificaciones-light.svg';
import { ReactComponent as NotificationsDark } from './Notificaciones-dark.svg';
import { ReactComponent as PanelOrange } from './panel-naranja.svg';
import { ReactComponent as PanelBrown } from './panel-marron.svg';
import { ReactComponent as PanelDark } from './panel-dark.svg';
import { ReactComponent as ProfileOrange } from './Perfil-naranja.svg';
import { ReactComponent as ProfileBrown } from './Perfil-marron.svg';
import { ReactComponent as ProfileDark } from './Perfil-dark.svg';
import { ReactComponent as BackOrange } from './Volver-naranja.svg';
import { ReactComponent as BackBrown } from './Volver-marron.svg';
import { ReactComponent as BackDark } from './Volver-dark.svg';
import { ReactComponent as BuySellOrange } from './compra-venta-naranja.svg';
import { ReactComponent as BuySellBrown } from './compra-venta-marron.svg';
import { ReactComponent as BuySellDark } from './compra-venta-dark.svg';
import { ReactComponent as ConversorOrange } from './conversor-naranja.svg';
import { ReactComponent as ConversorBrown } from './conversor-marron.svg';
import { ReactComponent as ConversorDark } from './conversor-dark.svg';

export const Svg = {
    HelpOrange,
    HelpBrown,
    HelpDark,
    Wallet,
    WalletOrange,
    WalletBrown,
    WalletDark,
    QuotationOrange,
    QuotationBrown,
    QuotationDark,
    Edit,
    HistoryOrange,
    HistoryBrown,
    HistoryDark,
    LogoutOrange,
    LogoutBrown,
    LogoutDark,
    MoonLight,
    MoonDark,
    GlassOrange,
    GlassBrown,
    GlassDark,
    NotificationsLight,
    NotificationsDark,
    PanelOrange,
    PanelBrown,
    PanelDark,
    ProfileOrange,
    ProfileBrown,
    ProfileDark,
    BackOrange,
    BackBrown,
    BackDark,
    BuySellOrange,
    BuySellBrown,
    BuySellDark,
    ConversorOrange,
    ConversorBrown,
    ConversorDark,
};
