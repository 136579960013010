import { Card, H5, P } from 'common';
import { Col, Row } from 'reactstrap';
import { BankAccountItem } from './BankAccountItem';
import { useEffect, useState } from 'react';
import './PaymentMethod.scss';
import { ProfileTabs } from '../ProfileTabs';
import { PaymentMethodEdition } from './PaymentMethodEdition';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getPaymentMethod } from '../profileService';
import { Button } from 'axolotl/common';

export const PaymentMethod = () => {
    const dispatch = useAppDispatch();
    const [renderPaymentMethodEdition, setRenderPaymentMethodEdition] =
        useState(false);
    const paymentMethod = useAppSelector(
        (state) => state.profile.paymentMethod,
    );

    useEffect(() => {
        dispatch(getPaymentMethod());
    }, []);

    const handleEdit = () => {
        setRenderPaymentMethodEdition(true);
    };

    // const handleConfirmDelete = () => {
    //     console.log('handleConfirmDelete');
    // };

    if (renderPaymentMethodEdition)
        return (
            <PaymentMethodEdition
                onClose={() => setRenderPaymentMethodEdition(false)}
            />
        );
    return (
        <>
            {/* <PaymentMethodsDeleteModal onConfirm={handleConfirmDelete} /> */}
            <Card gradientBackground className='payment-method-setting'>
                <ProfileTabs />
                <Col className='axolotl-payment-methods mt-5'>
                    <Row>
                        <H5 style={{ fontWeight: 'bold' }}>Configuración</H5>
                    </Row>
                    <Row>
                        <P>
                            Desde aca podes configurar los datos bancarios de tu
                            cuenta.
                        </P>
                    </Row>
                    <P
                        style={{
                            fontWeight: 'bold',
                            marginTop: 20,
                            marginBottom: 20,
                        }}
                    >
                        Cuenta Bancaria
                    </P>
                    <BankAccountItem
                        onEdit={handleEdit}
                        // onDelete={() =>
                        //     dispatch(
                        //         modalActions.setModalOpen(
                        //             MODAL_OPEN.PROFILE_PAYMENT_METHODS__CONFIRM_DELETE,
                        //         ),
                        //     )
                        // }
                    />
                    {!paymentMethod && (
                        <Row className='d-flex flex-row-reverse mt-4'>
                            <Button
                                style={{ width: 280 }}
                                onClick={() =>
                                    setRenderPaymentMethodEdition(true)
                                }
                            >
                                Agregar cuenta bancaria
                            </Button>
                        </Row>
                    )}
                </Col>
            </Card>
        </>
    );
};
