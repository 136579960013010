import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown,
} from 'reactstrap';
import { P } from '@common';
import React, { useContext } from 'react';
import { ThemeContext } from '../../../providers/ThemeProvider';
import './TextDropdown.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { LoaderWheel } from 'common/Loader/LoaderWheel';

export interface TextDropdownOption {
    id: string;
    text: string;
    component?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
}

interface TextDropdownProps {
    value: string | null;
    onChange: (option: TextDropdownOption) => void;
    options: TextDropdownOption[];
    component?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
    showBorder?: boolean;
    narrowBorder?: boolean;
    backgroundColor?: boolean;
    loading: boolean;
    noDataText?: string;
}

export const TextDropdown = (props: TextDropdownProps) => {
    const mixBackgroundLayout = localStorage.getItem('mix_background_layout');

    const dropDownItemColor =
        mixBackgroundLayout === 'light-only' ? 'black' : 'white';
    const dropDownBackgroundColor =
        mixBackgroundLayout === 'light-only' ? 'white' : 'rgb(17, 23, 39)';

    const getClassName = () => {
        let result = 'text-dropdown';
        if (mixBackgroundLayout === 'dark-only') result += ' dark';
        if (props.showBorder) result += ' show-border';
        if (props.narrowBorder) result += ' narrow-border-drp';
        return result;
    };

    return (
        <UncontrolledDropdown className={getClassName()}>
            <DropdownToggle
                tag='div'
                className='options-dropddown-toggle'
                style={{
                    paddingLeft: props.component ? 10 : 43,
                }}
            >
                {props.component && (
                    <props.component
                        style={{
                            height: '28px',
                            width: '28px',
                            marginRight: '7px',
                        }}
                    />
                )}
                <P style={{ minWidth: '65px' }}>
                    {
                        props.options.find(
                            (option) => option.id === props.value,
                        )?.text
                    }
                </P>
                <FontAwesomeIcon icon={faAngleDown} />
            </DropdownToggle>
            <DropdownMenu
                style={{
                    backgroundColor: props.backgroundColor
                        ? dropDownBackgroundColor
                        : '',
                    margin: '0px',
                }}
            >
                {props.loading ? (
                    <LoaderWheel />
                ) : // if props.data is empty, show noDataText
                props.options.length === 0 ? (
                    <P className='no-data-text'>{props.noDataText}</P>
                ) : (
                    props.options.map((option) => (
                        <DropdownItem
                            key={`balance-change-dropdown-item-${option.id}`}
                            onClick={() => props.onChange(option)}
                        >
                            {option.component ? (
                                <Row>
                                    <option.component
                                        style={{
                                            width: '28px',
                                            marginRight: '5px',
                                        }}
                                    />
                                    <P style={{ color: dropDownItemColor }}>
                                        {option.text}
                                    </P>
                                </Row>
                            ) : (
                                <P style={{ color: dropDownItemColor }}>
                                    {option.text}
                                </P>
                            )}
                        </DropdownItem>
                    ))
                )}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};
