import { Card } from 'common';
import { ProfileTabs } from '../ProfileTabs';
import { EditableImage } from './EditableImage/EditableImage';
import { Text } from 'axolotl/common';
import type { SettingsPersonalDataValuesSubmit } from './UserSettingsPersonalData';
import { SettingsPersonalData } from './UserSettingsPersonalData';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getAvatar, setPassword, setPersonalData } from '../profileService';
import { REQUEST_STATE } from 'redux/types';
import type { ChangePasswordEditionValues } from './ChangePasswordEdition/ChangePasswordEdition';
import { ChangePasswordEdition } from './ChangePasswordEdition/ChangePasswordEdition';
import { toast } from 'react-toastify';
import './Settings.scss';

export const Settings = () => {
    const dispatch = useAppDispatch();
    const [renderChangePasswordEdition, setRenderChangePasswordEdition] =
        useState(false);
    const { requestStates, userData } = useAppSelector(
        (state) => state.profile,
    );
    const loading =
        !userData || requestStates.personalData === REQUEST_STATE.LOADING;

    useEffect(() => {
        dispatch(getAvatar());
    }, []);

    const handlePersonalDataSubmit = (
        values: SettingsPersonalDataValuesSubmit,
    ) => {
        if (!userData) toast.error('Hubo un error');
        else {
            dispatch(
                setPersonalData({
                    password: values.password,
                    phone_country_cod: parseInt(values.phoneCode),
                    phone_number: parseInt(values.phoneNumber),
                }),
            )
                .unwrap()
                .then(() =>
                    toast.success('Se ha cambiado tus datos correctamente'),
                )
                .catch(() => toast.error('Hubo un error'));
        }
    };

    const handleChangePasswordSubmit = (
        values: ChangePasswordEditionValues,
    ) => {
        dispatch(
            setPassword({
                current_password: values.currentPassword,
                new_password: values.newPassword,
                re_new_password: values.reNewPassword,
            }),
        )
            .unwrap()
            .then(() => {
                toast.success('Se ha cambiado la contraseña con éxito');
                setRenderChangePasswordEdition(false);
            })
            .catch(() => toast.error('Hubo un error'));
    };

    if (renderChangePasswordEdition)
        return (
            <ChangePasswordEdition
                data={userData}
                loading={loading}
                onSubmit={handleChangePasswordSubmit}
                onBack={() => setRenderChangePasswordEdition(false)}
            />
        );
    return (
        <Card gradientBackground className='profile-settings'>
            <ProfileTabs />
            <div className='profile-settings-main'>
                <div className='avatar-and-title'>
                    <EditableImage />
                    <div className='names'>
                        <Text className='name'>Alan Raimondi</Text>
                        {/* <Text className='job-post'>Puesto de trabajo</Text> */}
                    </div>
                </div>
                <div className='mail-and-password'>
                    <Text className='label-text'>Email:</Text>
                    <Text className='value'>
                        {loading ? '' : userData?.email}
                    </Text>
                    <Text className='label-text'>Contraseña:</Text>
                    <div className='password-container'>
                        <Text className='password'>**********</Text>
                        <Text
                            onClick={() => setRenderChangePasswordEdition(true)}
                        >
                            Editar
                        </Text>
                    </div>
                </div>
            </div>
            <SettingsPersonalData
                data={userData}
                disabled={loading}
                onSubmit={handlePersonalDataSubmit}
                loading={
                    requestStates.setPersonalData === REQUEST_STATE.LOADING
                }
            />
        </Card>
    );
};
