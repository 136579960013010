import classNames from 'classnames';
import type { ReactNode } from 'react';
import { X } from 'react-feather';
import { Modal as ReactstrapModal } from 'reactstrap';
import './BuyModal.scss';

interface BuyModalProps {
    open?: boolean;
    onClose: () => void;
    image?: string;
    children?: ReactNode;
    className?: string;
}
export const BuyModal = (props: BuyModalProps) => {
    const className = classNames(
        'buy-modal',
        { ['no-image']: !props.image },
        props.className,
    );

    return (
        <ReactstrapModal
            style={{
                maxWidth: 1000,
            }}
            isOpen={props.open}
            className={className}
        >
            <div className='close-button' onClick={props.onClose}>
                <X size={20} />
            </div>
            <div className='content'>{props.children}</div>
            {props.image && <img src={props.image} alt='' />}
        </ReactstrapModal>
    );
};
