import Big from 'big.js';
import { Button } from 'common/Button';
import { getTokenPairs } from 'common/commonService';
import { MODAL_OPEN } from 'common/Modal/const';
import { modalActions } from 'common/Modal/modalSlice';
import { P } from 'common/Paragraph';
import { useEffect, useMemo } from 'react';
import type { ReactNode } from 'react';
import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { REQUEST_STATE } from 'redux/types';
import { personalData } from 'screens/role-user/profile/profileService';
import {
    addExchangeQuote,
    cancelExchangeLimit,
    getExchangeOrders,
    reactivateExchangeLimit,
} from 'screens/role-user/quotation/quotationService';
import type { ExchangeOrder } from 'screens/role-user/quotation/types';
import { ORDER_SIDE } from 'screens/role-user/quotation/types';
import { dotAndComma } from 'utils';
import { formatNumber } from 'utils/string';
import { ExchangeOrderStatusText } from '../common-cells';
import {
    getOrderStatus,
    ORDER_STATUS,
} from '../common-cells/ExchangeOrderStatus';
import { Table } from '../Table';
import { formatISODate } from '../utils';
import type { ExchangeTableItem } from './types';
import { EXCHANGE_TABLE_ITEM_TYPE } from './types';
import {
    exchangeTableItemTypeData,
    getExchangeTableData,
    isExchangeOrder,
    isExchangeWithdrawal,
    isExchangeDeposit,
} from './utils';
import { ORDER_TYPES } from '../../../utils/const';
import {
    getExchangeWithdrawal,
    getExchangeDeposit,
    getExchangeWithdrawalCrypto,
} from '../../../screens/role-user/wallet/walletService';
import { twoDecimals } from 'utils/currency';

export interface ExchangeTableOptions {
    limit?: number;
    onlyOrders?: boolean;
    showOnlyActive?: boolean;
    showOnlyNonActive?: boolean;
}

interface ExchangeTableProps {
    options?: ExchangeTableOptions;
}

export const ExchangeTable = (props: ExchangeTableProps) => {
    const dispatch = useAppDispatch();
    const { requestStates, exchangeOrders } = useAppSelector(
        (state) => state.quotation,
    );
    const {
        requestStates: requestStatesWallet,
        exchangeWithdrawals,
        exchangeDeposit,
        exchangeWithdrawalsCrypto,
    } = useAppSelector((state) => state.wallet);
    const {
        requestStates: commonRequestStates,
        coins,
        tokenPairs,
    } = useAppSelector((state) => state.common);
    const userType = useAppSelector((state) => state.profile.userType);
    const userId = useAppSelector((state) => state.profile.userData?.id);

    const loading =
        requestStates.getExchangeOrder === REQUEST_STATE.LOADING ||
        requestStatesWallet.getExchangeWithdrawal === REQUEST_STATE.LOADING ||
        commonRequestStates.coins === REQUEST_STATE.LOADING ||
        commonRequestStates.tokenPairs === REQUEST_STATE.LOADING ||
        requestStates.cancelExchangeLimit === REQUEST_STATE.LOADING ||
        requestStates.addExchangeQuote === REQUEST_STATE.LOADING ||
        requestStates.addExchangeLimit === REQUEST_STATE.LOADING;

    useEffect(() => {
        dispatch(getExchangeOrders());
        dispatch(getExchangeWithdrawal());
        dispatch(getExchangeWithdrawalCrypto());
        dispatch(getTokenPairs());
        dispatch(personalData());
        dispatch(getExchangeDeposit());
    }, []);

    const exchangeWithdrawalsJoined = useMemo(() => {
        if (exchangeWithdrawals && exchangeWithdrawalsCrypto) {
            return [...exchangeWithdrawals, ...exchangeWithdrawalsCrypto];
        } else return null;
    }, [exchangeWithdrawals, exchangeWithdrawalsCrypto]);

    /*
        Exchange order action dispatches
    */
    const handleExchangeOrderSuspend = (exchangeOrder: ExchangeOrder) => {
        dispatch(
            cancelExchangeLimit({
                exchange: exchangeOrder.exchange,
            }),
        )
            .unwrap()
            .then(() => {
                dispatch(getExchangeOrders());
            })
            .catch(() => {
                toast.error(
                    'Hubo un error al cancelar la orden de límite, intente lo más tarde.',
                );
            });
    };
    const handleExchangeOrderExecute = (exchangeOrder: ExchangeOrder) => {
        dispatch(
            addExchangeQuote({
                amount: exchangeOrder.amount,
                amount_in: exchangeOrder.amountIn,
                order_side: exchangeOrder.orderSide,
                symbol: exchangeOrder.symbol,
                tokenpair_id: exchangeOrder.tokenpairId,
                executeAfterConfirm: exchangeOrder.exchange,
            }),
        )
            .unwrap()
            .then(() => {
                dispatch(modalActions.setModalOpen(MODAL_OPEN.BUY_CONFIRM));
            })
            .catch(() => {
                toast.error(
                    'Hubo un error al ejecutar su orden (paso 1 de 2, crear orden de mercado), vuelve a intentarlo',
                );
            });
    };
    const handleExchangeOrderReactivate = (exchangeOrder: ExchangeOrder) => {
        dispatch(
            reactivateExchangeLimit({
                id: exchangeOrder.exchange,
            }),
        )
            .unwrap()
            .then(() => {
                toast.success(
                    'Se ha creado una orden de límite nueva exitosamente!',
                );
                dispatch(getExchangeOrders());
            })
            .catch(() => {
                toast.error(
                    'Hubo un error al reabrir la orden, intentelo nuevamente más tarde.',
                );
            });
    };

    const getExchangeOrderColumns = (
        key: string,
        exchangeTableItem: ExchangeTableItem,
    ): ReactNode => {
        switch (key) {
            case 'id':
                return exchangeTableItem.exchangeOrder?.id || '';
            case 'tokenpairId':
                const foundTP = tokenPairs?.find(
                    (tp) =>
                        tp.id === exchangeTableItem.exchangeOrder?.tokenpairId,
                );
                if (!tokenPairs || !exchangeTableItem.exchangeOrder || !foundTP)
                    return null;
                return (
                    <P style={{ fontWeight: 'bold' }}>{foundTP.symbolPretty}</P>
                );
            case 'createdAt':
                if (!exchangeTableItem.exchangeOrder) return null;
                return formatISODate(exchangeTableItem.exchangeOrder.createdAt);
            case 'orderSide':
                if (!exchangeTableItem.exchangeOrder) return null;
                if (
                    exchangeTableItem.exchangeOrder.orderSide === ORDER_SIDE.BUY
                )
                    return 'Compra';
                if (
                    exchangeTableItem.exchangeOrder.orderSide ===
                    ORDER_SIDE.SELL
                )
                    return 'Venta';
                return null;
            case 'amount':
                if (!exchangeTableItem.exchangeOrder) return null;
                const { amount, amountIn } = exchangeTableItem.exchangeOrder;

                // if coinType is MXN or USD, return only 2 decimals
                if (amountIn === 'MXN' || amountIn === 'USDT')
                    return `${twoDecimals(
                        dotAndComma(formatNumber(Big(amount))),
                    )} ${amountIn}`;

                return `${dotAndComma(formatNumber(Big(amount)))} ${amountIn}`;
            case 'priceTarget':
                if (!exchangeTableItem.exchangeOrder) return null;
                if (
                    exchangeTableItem.exchangeOrder.orderType ===
                    ORDER_TYPES.EXCHANGE_MARKET
                )
                    return null;
                const { priceTarget, tokenpairId } =
                    exchangeTableItem.exchangeOrder;
                const foundTPTarget = tokenPairs?.find(
                    (tp) => tp.id === tokenpairId,
                );
                if (!tokenPairs || !foundTPTarget) return null;
                return `${dotAndComma(formatNumber(Big(priceTarget)))} ${
                    exchangeTableItem.exchangeOrder.orderSide === ORDER_SIDE.BUY
                        ? foundTPTarget.baseCode
                        : foundTPTarget.quoteCode
                }`;
            case 'status':
                if (!exchangeTableItem.exchangeOrder) return null;
                const { active, executed, changeStateBy } =
                    exchangeTableItem.exchangeOrder;
                return (
                    <ExchangeOrderStatusText
                        active={active}
                        executed={executed}
                        changeStateBy={changeStateBy}
                    />
                );
            case 'actions':
                if (!exchangeTableItem.exchangeOrder) return null;
                const eo = exchangeTableItem.exchangeOrder;
                const orderStatus = getOrderStatus(
                    exchangeTableItem.exchangeOrder,
                    userType,
                    userId,
                    false,
                );
                if (
                    orderStatus === ORDER_STATUS.ACTIVE &&
                    eo.orderType !== ORDER_TYPES.EXCHANGE_MARKET
                ) {
                    return (
                        <Col>
                            <Row className='flex-nowrap'>
                                <Button
                                    color='danger'
                                    className='mr-2'
                                    disabled={loading}
                                    onClick={() =>
                                        handleExchangeOrderSuspend(eo)
                                    }
                                    style={{ minWidth: '135px' }}
                                >
                                    Suspender
                                </Button>
                                <Button
                                    color='info'
                                    disabled={loading}
                                    onClick={() =>
                                        handleExchangeOrderExecute(eo)
                                    }
                                    style={{ minWidth: '135px' }}
                                >
                                    Ejecutar
                                </Button>
                            </Row>
                        </Col>
                    );
                } else if (orderStatus === ORDER_STATUS.SUSPENDED) {
                    return (
                        <Button
                            disabled={loading}
                            onClick={() => handleExchangeOrderReactivate(eo)}
                        >
                            Activar
                        </Button>
                    );
                }

                return null;
            default:
                return null;
        }
    };

    const getExchangeWithdrawalColumns = (
        key: string,
        exchangeTableItem: ExchangeTableItem,
    ): ReactNode => {
        switch (key) {
            case 'id':
                return exchangeTableItem.exchangeWithdrawalsJoined?.id;
            case 'currencyId':
                const foundCoin = coins?.find(
                    (c) =>
                        parseInt(c.id) ===
                        exchangeTableItem.exchangeWithdrawalsJoined?.currencyId,
                );

                if (
                    !coins ||
                    !exchangeTableItem.exchangeWithdrawalsJoined ||
                    !foundCoin
                )
                    return null;
                return (
                    <P style={{ fontWeight: 'bold' }}>{foundCoin.tokenCode}</P>
                );
            case 'createdAt':
                if (!exchangeTableItem.exchangeWithdrawalsJoined) return null;
                return formatISODate(
                    exchangeTableItem.exchangeWithdrawalsJoined.createdAt,
                );
            case 'amount':
                const fc = coins?.find(
                    (c) =>
                        parseInt(c.id) ===
                        exchangeTableItem.exchangeWithdrawalsJoined?.currencyId,
                );
                if (
                    !coins ||
                    !exchangeTableItem.exchangeWithdrawalsJoined ||
                    !fc
                )
                    return null;

                // if coinType is MXN or USDT, return only 2 decimals
                if (fc.tokenCode === 'MXN' || fc.tokenCode === 'USDT')
                    return `${twoDecimals(
                        dotAndComma(
                            formatNumber(
                                Big(
                                    exchangeTableItem.exchangeWithdrawalsJoined
                                        ?.amount,
                                ),
                            ),
                        ),
                    )} ${fc.tokenCode}`;

                return `${dotAndComma(
                    formatNumber(
                        Big(exchangeTableItem.exchangeWithdrawalsJoined.amount),
                    ),
                )} ${fc.tokenCode}`;
            case 'status':
                if (!exchangeTableItem.exchangeWithdrawalsJoined) return null;
                const { active, executed, changeStateBy } =
                    exchangeTableItem.exchangeWithdrawalsJoined;
                return (
                    <ExchangeOrderStatusText
                        active={active}
                        executed={executed}
                        changeStateBy={changeStateBy}
                    />
                );

            default:
                return null;
        }
    };

    const getExchangeDepositColumns = (
        key: string,
        exchangeTableItem: ExchangeTableItem,
    ): ReactNode => {
        switch (key) {
            case 'id':
                return exchangeTableItem.exchangeDeposit?.id || '';
            case 'currencyId':
                const foundCoin = coins?.find(
                    (c) =>
                        parseInt(c.id) ===
                        exchangeTableItem.exchangeDeposit?.currencyId,
                );
                if (!coins || !exchangeTableItem.exchangeDeposit || !foundCoin)
                    return null;

                return (
                    <P style={{ fontWeight: 'bold' }}>{foundCoin.tokenCode}</P>
                );
            case 'createdAt':
                if (!exchangeTableItem.exchangeDeposit) return null;
                return formatISODate(
                    exchangeTableItem.exchangeDeposit.createdAt,
                );
            case 'amount':
                if (!exchangeTableItem.exchangeDeposit) return null;

                // if coinType is MXN or USDT, return only 2 decimals
                if (
                    exchangeTableItem.exchangeDeposit.currencyId === 2 ||
                    exchangeTableItem.exchangeDeposit.currencyId === 5
                )
                    return `${twoDecimals(
                        dotAndComma(
                            formatNumber(
                                Big(exchangeTableItem.exchangeDeposit.amount),
                            ),
                        ),
                    )})`;

                return dotAndComma(
                    formatNumber(Big(exchangeTableItem.exchangeDeposit.amount)),
                );
            case 'status':
                if (!exchangeTableItem.exchangeDeposit) return null;
                const { active, executed, changeStateBy } =
                    exchangeTableItem.exchangeDeposit;
                return (
                    <ExchangeOrderStatusText
                        active={active}
                        executed={executed}
                        changeStateBy={changeStateBy}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Table
            columns={[
                {
                    id: '__id',
                    header: 'Número de operación',
                    cell: (value, row) => {
                        const exchangeTableItem = row as ExchangeTableItem;
                        if (isExchangeOrder(exchangeTableItem)) {
                            return getExchangeOrderColumns(
                                'id',
                                exchangeTableItem,
                            );
                        } else if (isExchangeWithdrawal(exchangeTableItem)) {
                            return getExchangeWithdrawalColumns(
                                'id',
                                exchangeTableItem,
                            );
                        } else if (isExchangeDeposit(exchangeTableItem)) {
                            return getExchangeDepositColumns(
                                'id',
                                exchangeTableItem,
                            );
                        }
                    },
                },
                {
                    id: 'type',
                    header: 'Tipo',
                    cell: (value, row) => {
                        const exchangeTableItem = row as ExchangeTableItem;
                        let typeData = null;
                        if (isExchangeOrder(exchangeTableItem)) {
                            if (
                                exchangeTableItem.exchangeOrder?.orderType ===
                                ORDER_TYPES.EXCHANGE_MARKET
                            )
                                typeData =
                                    exchangeTableItemTypeData[
                                        EXCHANGE_TABLE_ITEM_TYPE.ORDER +
                                            '-market'
                                    ];
                            if (
                                exchangeTableItem.exchangeOrder?.orderType ===
                                ORDER_TYPES.EXCHANGE_LIMIT
                            )
                                typeData =
                                    exchangeTableItemTypeData[
                                        EXCHANGE_TABLE_ITEM_TYPE.ORDER +
                                            '-limit'
                                    ];
                        } else if (isExchangeWithdrawal(exchangeTableItem)) {
                            typeData =
                                exchangeTableItemTypeData[
                                    EXCHANGE_TABLE_ITEM_TYPE.WITHDRAWAL
                                ];
                        } else if (isExchangeDeposit(exchangeTableItem)) {
                            typeData =
                                exchangeTableItemTypeData[
                                    EXCHANGE_TABLE_ITEM_TYPE.DEPOSIT
                                ];
                        }
                        if (!typeData) return null;
                        return (
                            <P style={{ color: typeData.color }}>
                                {typeData.text}
                            </P>
                        );
                    },
                },
                {
                    id: '__createdAt',
                    header: 'Fecha de creación',
                    cell: (value, row) => {
                        const exchangeTableItem = row as ExchangeTableItem;
                        if (isExchangeOrder(exchangeTableItem)) {
                            return getExchangeOrderColumns(
                                'createdAt',
                                exchangeTableItem,
                            );
                        } else if (isExchangeWithdrawal(exchangeTableItem)) {
                            return getExchangeWithdrawalColumns(
                                'createdAt',
                                exchangeTableItem,
                            );
                        } else if (isExchangeDeposit(exchangeTableItem)) {
                            return getExchangeDepositColumns(
                                'createdAt',
                                exchangeTableItem,
                            );
                        }
                    },
                },
                {
                    id: '__currencyOrPair',
                    header: 'Par o moneda',
                    cell: (value, row) => {
                        const exchangeTableItem = row as ExchangeTableItem;
                        if (isExchangeOrder(exchangeTableItem)) {
                            return getExchangeOrderColumns(
                                'tokenpairId',
                                exchangeTableItem,
                            );
                        } else if (isExchangeWithdrawal(exchangeTableItem)) {
                            return getExchangeWithdrawalColumns(
                                'currencyId',
                                exchangeTableItem,
                            );
                        } else if (isExchangeDeposit(exchangeTableItem)) {
                            return getExchangeDepositColumns(
                                'currencyId',
                                exchangeTableItem,
                            );
                        }
                    },
                },
                {
                    id: '__buySell',
                    header: 'Compra/Venta',
                    cell: (value, row) => {
                        const exchangeTableItem = row as ExchangeTableItem;
                        if (isExchangeOrder(exchangeTableItem)) {
                            return getExchangeOrderColumns(
                                'orderSide',
                                exchangeTableItem,
                            );
                        }
                        return null;
                    },
                },
                {
                    id: '__amount',
                    header: 'Monto',
                    cell: (value, row) => {
                        const exchangeTableItem = row as ExchangeTableItem;
                        if (isExchangeOrder(exchangeTableItem)) {
                            return getExchangeOrderColumns(
                                'amount',
                                exchangeTableItem,
                            );
                        } else if (isExchangeWithdrawal(exchangeTableItem)) {
                            return getExchangeWithdrawalColumns(
                                'amount',
                                exchangeTableItem,
                            );
                        } else if (isExchangeDeposit(exchangeTableItem)) {
                            return getExchangeDepositColumns(
                                'amount',
                                exchangeTableItem,
                            );
                        }
                    },
                },
                {
                    id: '__targetPrice',
                    header: 'Precio objetivo',
                    cell: (value, row) => {
                        const exchangeTableItem = row as ExchangeTableItem;
                        if (isExchangeOrder(exchangeTableItem)) {
                            return getExchangeOrderColumns(
                                'priceTarget',
                                exchangeTableItem,
                            );
                        }
                        return null;
                    },
                },
                {
                    id: '__status',
                    header: 'Estado',
                    cell: (value, row) => {
                        const exchangeTableItem = row as ExchangeTableItem;
                        if (isExchangeOrder(exchangeTableItem)) {
                            return getExchangeOrderColumns(
                                'status',
                                exchangeTableItem,
                            );
                        } else if (isExchangeWithdrawal(exchangeTableItem)) {
                            return getExchangeWithdrawalColumns(
                                'status',
                                exchangeTableItem,
                            );
                        } else if (isExchangeDeposit(exchangeTableItem)) {
                            return getExchangeDepositColumns(
                                'status',
                                exchangeTableItem,
                            );
                        }
                    },
                },
                {
                    id: '__actions',
                    header: 'Acciones',
                    cell: (value, row) => {
                        const exchangeTableItem = row as ExchangeTableItem;
                        if (isExchangeOrder(exchangeTableItem)) {
                            return getExchangeOrderColumns(
                                'actions',
                                exchangeTableItem,
                            );
                        } else if (isExchangeWithdrawal(exchangeTableItem)) {
                            return getExchangeWithdrawalColumns(
                                'actions',
                                exchangeTableItem,
                            );
                        }
                        return null;
                    },
                },
            ]}
            data={getExchangeTableData(
                exchangeOrders,
                exchangeWithdrawalsJoined,
                exchangeDeposit,
                props.options,
            )}
            loading={
                requestStates.getExchangeOrder === REQUEST_STATE.LOADING &&
                requestStatesWallet.getExchangeWithdrawal ===
                    REQUEST_STATE.LOADING
            }
            noDataText='No hay operaciones'
        />
    );
};
