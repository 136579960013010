import { Text } from 'axolotl/common';
import type { ReactNode } from 'react';
import { Col, Row } from 'reactstrap';
import './SessionCard.scss';

interface SessionCardCommonProps {
    title: string;
    subtitle: string;
    children?: ReactNode;
}
interface SessionCardProps extends SessionCardCommonProps {
    image: string;
}

type SessionCardImagelessProps = SessionCardCommonProps;

const TitleAndSubtitle = ({
    title,
    subtitle,
}: {
    title: string;
    subtitle: string;
}) => {
    return (
        <Col className='title-and-subtitle'>
            <Row>
                <Text title className='title'>
                    {title}
                </Text>
            </Row>
            <Row>
                <Text className='subtitle'>{subtitle}</Text>
            </Row>
        </Col>
    );
};

const SessionCardMain = (props: SessionCardProps) => {
    return (
        <div className='session-card'>
            <div className='container'>
                <div className='top-border' />
                <TitleAndSubtitle
                    title={props.title}
                    subtitle={props.subtitle}
                />
                {props.children}
            </div>
            <img src={props.image} />
        </div>
    );
};

const SessionCardImageless = (props: SessionCardImagelessProps) => {
    return (
        <div className='session-card imageless'>
            <div className='container'>
                <div className='top-border' />
                <TitleAndSubtitle
                    title={props.title}
                    subtitle={props.subtitle}
                />
                {props.children}
            </div>
        </div>
    );
};

/* Dot notation */
SessionCardMain.Imageless = SessionCardImageless;
export const SessionCard = SessionCardMain;
