import bannerImage from 'axolotl/assets/images/new/Banner-02.png';
import { Button, Text } from 'axolotl/common';
import { ROUTE_PATH } from 'layout/routes/const';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/store';
import { getTotalBalanceEquivalent } from 'utils';
import './BalancePanel.scss';

export const BalancePanel = () => {
    const navigate = useNavigate();
    const { balances } = useAppSelector((state) => state.common);

    const totalBalanceEquivalent = getTotalBalanceEquivalent(balances);

    return (
        <div className='axolotl-balance-panel'>
            <img src={bannerImage} alt='' />
            <div className='floating-content'>
                <Text className='equivalent-text'>
                    Equivalente total en MXN
                </Text>
                <Text className='balance'>{`$${totalBalanceEquivalent.amountMXN}`}</Text>
                <Text onClick={() => navigate(ROUTE_PATH.WALLET)}>
                    Ver cuenta
                </Text>
                <div className='buttons'>
                    <Button onClick={() => navigate(ROUTE_PATH.WALLET)}>
                        Comprar
                    </Button>
                    <Button
                        onClick={() => navigate(ROUTE_PATH.WALLET)}
                        className='white'
                    >
                        Depositar
                    </Button>
                </div>
            </div>
        </div>
    );
};
